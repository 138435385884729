<script setup lang="ts">
type Emits = {
    (e: 'changed'): void
}

type Props = {
    initialValue: boolean
}

const { initialValue } = defineProps<Props>()

const emit = defineEmits<Emits>()
</script>

<template>
    <label class="relative inline-flex items-center cursor-pointer">
        <input
            @change="emit('changed')"
            type="checkbox"
            :value="initialValue"
            class="sr-only peer"
            :checked="initialValue"
        />

        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-[3px] peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-main"></div>
    </label>
</template>
