<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import useTabs from '@/composables/product/useTabs'
import Reviews from '@/components/Product/ProductTabs/Reviews.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import DeliveryTab from '@/components/Product/ProductTabs/DeliveryTab.vue'
import ReturnTab from '@/components/Product/ProductTabs/ReturnTab.vue'

type Props = {
    description: string
    product: Product
}

const { description, product } = defineProps<Props>()
const { tabs, currTab, changeTab } = useTabs(product)
</script>

<template>
    <appear-transition>
        <div
            class="bg-page p-5 lg:p-10 rounded-xl shadow-md"
            id="product-description"
        >
            <tab-group :selected-index="currTab" @change="changeTab">
                <tab-list>
                    <div class="space-x-3">
                        <tab
                            v-for="tab in tabs"
                            :key="tab.title"
                            v-slot="{ selected }"
                            class="outline-none text-xl border-r border-border last:border-none pr-3"
                        >

                            <p
                                class="flex items-center text-[1rem] md:text-[1.2rem] border-b-2 pb-0.5 hover:text-main transition-colors font-bold"
                                :class="{
                                    'border-main text-main': selected,
                                    'border-b-transparent text-gray-700': !selected,
                                }"
                            >
                                {{ tab.title }}

                                <div
                                    v-if="tab.badge && tab.badge > 0"
                                    class="bg-main text-white w-5 h-5 flex items-center justify-center rounded-full font-normal text-xs ml-1.5"
                                >
                                    {{ tab.badge }}
                                </div>
                            </p>
                        </tab>
                    </div>
                </tab-list>

                <div class="mt-5 text-gray-500 text-md lg:text-lg min-h-[150px]">
                    <tab-panels>
                        <tab-panel>
                            <span v-html="description"></span>
                        </tab-panel>
                        <tab-panel>
                            <delivery-tab />
                        </tab-panel>
                        <tab-panel>
                            <return-tab />
                        </tab-panel>
                        <tab-panel>
                            <reviews :product-id="product.id" />
                        </tab-panel>
                    </tab-panels>
                </div>
            </tab-group>
        </div>
    </appear-transition>
</template>