import type { Order } from '@shared/types/models'
import type { ServerResponse } from '@shared/types/server'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import handleServerError from '@shared/modules/handleServerError'

export default (orderToken: string) => {
    const order = ref<Order | null>(null)
    const loading = ref<boolean>(false)
    const intervalId = ref<number | null>(null)

    onMounted(() => {
        loading.value = true
        fetchOrder()
        loopFetchingOrders()
    })

    function loopFetchingOrders(): void {
        intervalId.value = window.setInterval(() => {
            fetchOrder()

            if (
                order.value &&
                order.value.payment &&
                order.value.payment.status !== 'created' &&
                intervalId.value
            ) {
                window.clearInterval(intervalId.value)
            }
        }, 5000)
    }

    function fetchOrder(): void {
        axios.get<ServerResponse<Order>>(`/api/v2/orders/${orderToken}`)
            .then(resp => order.value = resp.data.data)
            .catch(handleServerError)
            .finally(() => loading.value = false)
    }

    return {
        order,
        loading,
    }
}
