<script setup lang="ts">
import type { CartItem } from '@shared/types'
import CartCache from '@shared/modules/cart/CartCache'
import showToast from '@shared/modules/showToast'
import dispatchEvent from '@shared/modules/dispatchEvent'
import GAProductEvent from '@/modules/ga/GAProductEvent'
import { events } from '@shared/appConfig'

type Props = {
    cartItem: CartItem
}

type Emits = {
    (e: 'added'): void
}

const emit = defineEmits<Emits>()
const { cartItem } = defineProps<Props>()

function isQuantityMoreThanAvailable() {
    let totalQuantity = cartItem.quantity
    const alreadyInCart = CartCache.getCartItem(cartItem.product.slug)

    if (alreadyInCart) {
        totalQuantity += alreadyInCart.quantity
    }

    if (totalQuantity <= cartItem.product.quantity) {
        return false
    }

    showToast({
        text: Lang.get('order.product_not_enough_quantity', {
            max: cartItem.product.quantity,
        }),
        success: false,
    })

    return true
}

function isProductOutOfStock() {
    if (cartItem.product.availability !== 'out') {
        return false
    }

    showToast({
        text: Lang.get('common.product_out_of_stock'),
        success: false,
    })

    return true
}

function addToCart(): void {
    if (isProductOutOfStock() || isQuantityMoreThanAvailable()) {
        return
    }

    CartCache.addCartItem(cartItem)

    dispatchEvent(events.cartUpdated)

    showToast({ text: Lang.get('common.product_add_to_cart') })

    GAProductEvent.push({
        event: 'add_to_cart',
        ecommerce: {
            items: [
                {
                    item_name: cartItem.product.title,
                    item_id: cartItem.product.id,
                    item_variant: cartItem.product.measure_label,
                    quantity: cartItem.quantity,
                    price: cartItem.product.price.toFixed(2),
                    ...GAProductEvent.getCategories(cartItem.product),
                },
            ],
        },
    })

    emit('added')
}
</script>

<template>
    <button
        type="button"
        class="py-2.5 px-4 rounded-md transition-colors hover:bg-main-green-hover bg-main-green text-white shadow-sm"
        :class="{
            'opacity-50 cursor-not-allowed': cartItem.product.availability === 'out',
        }"
        @click.prevent="addToCart"
        aria-label="Add to cart"
    >
        <span class="space-x-1.5 uppercase">
            <i aria-hidden="true" class="lni lni-cart font-black"></i>

            <span>{{ $trans.get('common.buy') }}</span>
        </span>
    </button>
</template>
