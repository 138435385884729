<script setup lang="ts">
import type { Cart } from '@shared/types'
import { ref, computed } from 'vue'
import listenEvent from '@shared/modules/listenEvent'
import { events } from '@shared/appConfig'
import Modal from '@shared/components/Modal.vue'
import CartItems from '@/components/Cart/CartItems/CartItems.vue'
import Checkout from '@/components/Cart/Checkout/Checkout.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'

type Props = {
    opened: boolean
    cart: Cart
}

type Emits = {
    (e: 'close'): void
}

const emit = defineEmits<Emits>()
const { opened, cart } = defineProps<Props>()

const showCheckout = ref<boolean>(false)

const modalData = computed<{ title: string, icon: string }>(() => {
    if (showCheckout.value) {
        return {
            title: Lang.get('common.order_details'),
            icon: 'lni lni-delivery',
        }
    }

    return {
        title: Lang.get('common.cart'),
        icon: 'lni lni-cart',
    }
})

listenEvent<boolean>(events.orderSent, () => {
    showCheckout.value = false
})

listenEvent<boolean>(events.showCheckoutPage, show => showCheckout.value = show)
</script>

<template>
    <teleport to="body">
        <appear-transition>
            <modal
                v-if="opened"
                @close="emit('close')"
                :opened="opened"
                :title="modalData.title"
                :icon="modalData.icon"
                classes="font-main bg-page-second"
            >
                <checkout v-if="showCheckout" :cart="cart" />
                <cart-items v-else :cart="cart" />
            </modal>
        </appear-transition>
    </teleport>
</template>
