<script setup lang="ts">
import { ref } from 'vue'
import FilledStar from '@shared/components/Icons/FilledStar.vue'
import StarsCover from '@/components/Product/ProductTabs/StarsCover.vue'

type Props = {
    defaultRate: number
}

type Emits = {
    (e: 'chosen', rate: number): void
}

const { defaultRate } = defineProps<Props>()
const emit = defineEmits<Emits>()

const coverWidth = ref<number>(getWidthBasedOnRate(defaultRate))
const currRate = ref<number>(defaultRate)

function setStars(rate: number) {
    coverWidth.value = getWidthBasedOnRate(rate)
    currRate.value = rate
    emit('chosen', rate)
}

function getWidthBasedOnRate(rate: number) {
    return 100 - (rate * 20)
}
</script>

<template>
    <div>
        <h2 class="text-lg mb-1">
            {{ $trans.get('reviews.choose_rate') }}:
        </h2>

        <div class="inline-flex gap-1 relative">
            <filled-star
                v-for="rate in 5"
                :key="rate"
                @click="setStars(rate)"
                @mouseover="setStars(rate)"
                width="40"
                height="40"
                class="cursor-pointer relative hover:scale-110 transition-transform drop-shadow-sm"
            />

            <stars-cover :cover-width="coverWidth" />
        </div>
    </div>

    <p class="text-sm -mt-1 opacity-90">
        {{ $trans.get('reviews.your_rate_is') }}
        <b class="text-yellow-600 text-[1rem]">{{ currRate }}</b>
        {{ $trans.get('reviews.out_of') }}
        <b>5</b>
    </p>
</template>
