<script setup lang="ts">
import type { Product } from '@shared/types/models'
import type { PaginatorServerResponse } from '@shared/types/server'
import { ref } from 'vue'
import showToast from '@shared/modules/showToast'
import axios from 'axios'
import productTitle from '@/modules/productTitle'
import linkTo from '@shared/modules/linkTo'

const searchQuery = ref('')
const products = ref<Product[]>([])
const isLoading = ref(false)

function displaySuggestions(): void {
    if (searchQuery.value === '' || isLoading.value) {
        products.value = []
        return
    }

    isLoading.value = true

    const params = {
        per_page: 10,
        select: 'id,title,title_ru,price,sm_image_uri,slug,measure_name,measure_class,measure_value',
        q: searchQuery.value,
    }

    axios
        .get<PaginatorServerResponse<Product[]>>('/api/v2/products/search', {
            params,
        })
        .then(resp => handleServerResponse(resp.data))
        .catch(err => {
            console.error(err)
            showToast({ text: Lang.get('common.server_error'), success: false })
        })
        .finally(() => (isLoading.value = false))
}

function handleServerResponse(resp: PaginatorServerResponse<Product[]>): void {
    if (resp.status === 'error') {
        showToast({ text: Lang.get('common.server_error'), success: false })
        console.error(resp.message || Lang.get('common.server_error'))
        return
    }

    products.value = resp.data.data
}

function suggestionClickHandler(product: Product): void {
    products.value = []
    searchQuery.value = ''

    window.location.href = `/product/${product.slug}`
}

function redirectToSearchPage(e: Event): void {
    const query = searchQuery.value

    products.value = []
    searchQuery.value = ''

    window.location.href = linkTo(`/products/search?query=${query}`)
}
</script>

<template>
    <div>
        <input
            v-model="searchQuery"
            type="search"
            :placeholder="$trans.get('common.search_product')"
            class="py-3 pl-4 pr-16 border border-border rounded-md w-full"
            @keyup="displaySuggestions"
            @keydown.enter.prevent="redirectToSearchPage"
        />

        <button
            type="button"
            @click="redirectToSearchPage"
            class="absolute right-0 top-0 rounded-r-md bottom-0 flex items-center justify-center px-5 bg-main font-bold hover:bg-main-hover transition-bg"
            aria-label="Search"
        >
            <i
                class="lni lni-search-alt font-black text-white text-lg"
                aria-hidden="true"
            ></i>
        </button>
    </div>

    <div
        v-if="products.length > 0"
        class="bg-white border border-border rounded-md shadow-lg absolute z-10 left-0 right-0 top-full mt-1 divide-y divide-border max-h-[600px] overflow-y-auto"
    >
        <a
            v-for="product in products"
            :key="product.id"
            href="javascript:"
            @click="suggestionClickHandler(product)"
            class="py-2 px-4 leading-6 hover:bg-page-second flex gap-4 items-center justify-between"
        >
            <div class="flex gap-5 items-center">
                <img
                    :src="`/${product.sm_image_uri}`"
                    :alt="productTitle(product)"
                    class="w-9 h-full rounded-md object-cover object-center"
                />

                <div class="flex flex-col">
                    <span>{{ productTitle(product) }}</span>

                    <span class="space-x-1">
                        <span class="text-gray-700 text-lg space-x-1">
                            <b>{{ product.price }}</b>
                            <span>{{ $trans.get('common.uah') }}</span>
                        </span>

                        <span class="text-gray-500">/</span>

                        <span class="text-gray-500">
                            {{ product.measure_label }}
                        </span>
                    </span>
                </div>
            </div>
        </a>
    </div>
</template>
