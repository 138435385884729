import type { ReviewForm } from '@shared/types'
import type { Review } from '@shared/types/models'
import type { ServerResponse } from '@shared/types/server'
import { ref, computed } from 'vue'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'
import showToast from '@shared/modules/showToast'

export default (productId: number) => {
    const loading = ref<boolean>(false)
    const formIsVisible = ref<boolean>(false)

    const form = ref<ReviewForm>({
        name: Auth ? Auth.name : '',
        comment: '',
        rate: 5,
        token: Auth ? Auth.token : null,
        product_id: productId,
        lang: Locale,
    })

    const allowedToSubmit = computed(() => {
        return form.value.name.length > 2 && form.value.rate > 0
    })

    function submitNewReview(): void {
        if (!allowedToSubmit.value || loading.value) {
            return
        }

        loading.value = true

        axios.post<ServerResponse<Review>>('/api/v2/reviews', form.value)
            .then(resp => {
                formIsVisible.value = false

                if (resp.data.message) {
                    showToast({ text: resp.data.message })
                }
            })
            .catch(handleServerError)
            .finally(() => loading.value = false)
    }

    return {
        form,
        loading,
        formIsVisible,
        allowedToSubmit,
        submitNewReview,
    }
}