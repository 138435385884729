<script setup lang="ts">
import type { CartItem } from '@shared/types'
import type { Product } from '@shared/types/models'
import { ref } from 'vue'
import AddToCartButton from '@/components/Cart/AddToCartButton.vue'
import AddToWishlistButton from '@/components/Product/AddToWishlistButton.vue'

type Props = {
    product: Product
}

const { product } = defineProps<Props>()

const cartItem = ref<CartItem>({
    product,
    quantity: 1,
})
</script>

<template>
    <div class="inline-flex flex-col gap-3">
        <add-to-wishlist-button :product-id="product.id" />

        <div class="flex gap-4 items-center">
            <small>
                <span class="text-gray-500 mr-1">
                    {{ $trans.get('common.amount_in_stock') }}:
                </span>
                <b>{{ cartItem.product.quantity }}</b>
            </small>
        </div>
    </div>
</template>
