import type { GADynamicParams, GADynamicRemarketingEvent } from '@/types/ga'

export default class {
    public static push(dynamicParams: GADynamicParams): void {
        if (process.env.NODE_ENV === 'development') {
            return
        }

        const layer: GADynamicRemarketingEvent = {
            event: 'Dynamic Remarketing',
            dynamicParams,
        }

        dataLayer.push(layer)
    }
}
