import type { ServerResponse } from '@shared/types/server'
import type { Cart, CheckoutFormData } from '@shared/types'
import type { Order } from '@shared/types/models'
import { reactive, ref } from 'vue'
import { events } from '@shared/appConfig'
import showToast from '@shared/modules/showToast'
import handleServerError from '@shared/modules/handleServerError'
import listenEvent from '@shared/modules/listenEvent'
import axios from 'axios'

export default (cart: Cart) => {
    const isLoading = ref<boolean>(false)

    const formData = reactive<CheckoutFormData>({
        token: Auth ? Auth.token : null,
        name: Auth ? Auth.name : null,
        phone: Auth ? Auth.phone : null,
        city: null,
        address: null,
        email: Auth ? Auth.email : null,
        use_cashback: cart.cashback.discount * 100,
        payment_method: cart.paymentMethod.slug,
        comment: null,
        platform: 'website',
        delivery_id: cart.delivery.id,
        products: cart.cartItems.map(item => {
            return {
                id: item.product.id,
                amount: item.quantity,
            }
        }),
    })

    listenEvent(events.cartUpdated, () => {
        formData.use_cashback = cart.cashback.discount * 100
        formData.delivery_id = cart.delivery.id
        formData.payment_method = cart.paymentMethod.slug
    })

    function sendOrder(): void {
        if (isLoading.value) return

        isLoading.value = true

        axios
            .post<ServerResponse<Order>>('/api/v2/orders', formData)
            .then(resp => handleServerResponse(resp.data))
            .catch(handleServerError)
            .finally(() => (isLoading.value = false))
    }

    function handleServerResponse(resp: ServerResponse<Order>): void {
        showToast({
            text: resp.message!,
            success: resp.status === 'success',
            duration: 7000,
        })

        if (resp.status === 'error') {
            return
        }

        window.location.href = `/thanks?order=${resp.data.token}`
    }

    return {
        isLoading,
        formData,
        sendOrder,
    }
}
