import type { Cart, CartItem } from '@shared/types'

export default (cart: Cart): Cart => {
    cart.sum = getSum(cart.cartItems)
    cart.totalSum = cart.sum

    const deliveryPrice = getDeliveryPrice(cart)

    cart.delivery.price = deliveryPrice
    cart.totalSum += deliveryPrice

    if (wantsToPayWithCashback(cart)) {
        const allowedToSpend = getAllowedToSpendCashback(cart)

        cart.cashback.discount = allowedToSpend
        cart.totalSum -= allowedToSpend
    } else {
        cart.cashback.discount = 0
    }

    return cart
}

function getDeliveryPrice(cart: Cart): number {
    const deliveryId = cart.delivery.id

    switch (deliveryId) {
        case 1:
            return GlobalConfig.deliveryNovayaPochtaPrice
        case 2:
            return GlobalConfig.deliveryUkrpochtaPrice
        case 3:
            return GlobalConfig.deliveryCourierPrice
    }

    return 0
}

function wantsToPayWithCashback(cart: Cart): boolean {
    return cart.cashback.use && cart.cashback.user > 0
}

function getAllowedToSpendCashback(cart: Cart): number {
    if (cart.cashback.user > cart.totalSum) {
        return cart.totalSum
    }

    return cart.cashback.user
}

function getSum(cartItems: CartItem[]): number {
    return cartItems.reduce((prev, curr) => {
        return prev + (curr.product.price * curr.quantity)
    }, 0)
}

