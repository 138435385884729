export default (name: string, value: string, initialUrl?: string): string => {
    const url = new URL(initialUrl || window.location.href)
    const oldValue = url.searchParams.get(name)

    if (oldValue === value) {
        return url.toString()
    }

    if (value === '') {
        url.searchParams.delete(name)
        return url.toString()
    }

    url.searchParams.set(name, value)

    return url.toString().replace(/%2C/g, ',')
}
