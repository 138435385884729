import type { Product } from '@shared/types/models'
import type { GAProductEvent } from '@/types/ga'

export default class {
    public static getCategories(product: Product): { [key: string]: string } {
        if (process.env.NODE_ENV === 'development') {
            return {}
        }

        const result = {}

        if (!product.categories) {
            return {}
        }

        product.categories.forEach((category, i) => {
            let num = i === 0 ? '' : `${i + 1}`
            result[`item_category${num}`] = category.name
        })

        return result
    }

    public static push(layer: GAProductEvent): void {
        if (process.env.NODE_ENV === 'development') {
            return
        }

        dataLayer.push({ ecommerce: null })
        dataLayer.push(layer)
    }
}
