import type { Cart } from '@shared/types'
import { onMounted, ref } from 'vue'
import listenEvent from '@shared/modules/listenEvent'
import { constants, events } from '@shared/appConfig'
import calculateCart from '@/modules/cart/calculateCart'
import CartCache from '@shared/modules/cart/CartCache'

export default () => {
    const cart = ref<Cart>({
        cashback: {
            use: false,
            user: 0,
            discount: 0,
        },
        delivery: {
            id: constants.defaultDeliveryId,
            price: 0,
            items: [],
        },
        paymentMethod: {
            slug: null,
            items: [],
        },
        cartItems: [],
        sum: 0,
        totalSum: 0,
    })

    onMounted(() => recalculateCart())
    listenEvent(events.cartUpdated, () => recalculateCart())

    function recalculateCart(): void {
        cart.value.cartItems = CartCache.getCartItems()
        cart.value = calculateCart(cart.value)
    }

    return {
        cart,
    }
}
