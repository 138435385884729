<script setup lang="ts">
import useReviews from '@/composables/product/useReviews'
import Spinner from '@shared/components/Spinner.vue'
import NewReview from '@/components/Product/ProductTabs/NewReview.vue'
import Review from '@/components/Product/ProductTabs/Review.vue'
import LoadMoreButton from '@shared/components/Buttons/LoadMoreButton.vue'

type Props = {
    productId: number
}

const { productId } = defineProps<Props>()
const { reviews, loading, nextPageUrl, loadMore } = useReviews(productId)
</script>

<template>
    <new-review :productId="productId" />

    <div v-if="loading" class="flex gap-5 items-center justify-center pt-11">
        <spinner />

        <h2 class="text-lg md:text-xl">
            {{ $trans.get('reviews.loading_reviews') }}...
        </h2>
    </div>

    <h2 v-else-if="reviews.length === 0" class="text-center pt-4 text-xl">
        {{ $trans.get('reviews.no_reviews') }}
    </h2>

    <div v-else class="mt-1">
        <review
            v-for="review in reviews"
            :key="review.id"
            :review="review"
        />
    </div>

    <div v-if="nextPageUrl" class="flex justify-center mt-5">
        <load-more-button @click="loadMore" />
    </div>
</template>