import type { ServerResponse } from '@shared/types/server'
import type { Delivery } from '@shared/types/models'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

export default async (): Promise<Delivery[]> => {
    try {
        const response = await axios.get<ServerResponse<Delivery[]>>('/api/v2/deliveries', {
            params: { lang: Locale },
        })
        return response.data.data
    } catch (err) {
        handleServerError(err)
    }

    return []
}
