<script setup lang="ts">
import { computed } from 'vue'

type Props = {
    icon?: string
    href?: string
    type?: 'button' | 'submit'
    classes?: string
}

const { icon, href, type, classes } = defineProps<Props>()

const styles = computed<string[]>(() => {
    return [
        'text-white text-center font-bold py-3 px-4 md:px-6 rounded-lg inline-block bg-main hover:bg-main-hover transition-colors',
        classes ? classes : '',
    ]
})
</script>

<template>
    <a v-if="href" :href="href" :class="styles">
        <i v-if="icon" class='lni font-bold mr-3' :class="icon"></i>
        <slot />
    </a>

    <button v-else :type="type || 'button'" :class="styles">
        <i v-if="icon" class='lni font-bold mr-3' :class="icon"></i>
        <slot />
    </button>
</template>
