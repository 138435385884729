import type { LanguageLink } from '@shared/types'
import Cookies from 'js-cookie'
import { cookieKeys } from '@shared/appConfig'
import { computed } from 'vue'

export default () => {
    const currentLang = computed<LanguageLink | null>(() => {
        const res = LanguagesLinks.find(link => link.slug === Locale)
        return res || null
    })

    function changeLanguageInUrl(lang: string): string {
        let path = location.pathname
        let langFromUrl = path.substring(1, 3)

        const languages = LanguagesLinks.map(link => link.slug)

        if (languages.includes(langFromUrl)) {
            const replacement = lang === DefaultLocale ? '' : `/${lang}`
            const newPath = path.replace(`/${langFromUrl}`, replacement)

            return newPath === '' ? '/' : newPath
        }

        return lang === DefaultLocale ? path : `/${lang}${path}`
    }

    function switchLanguage(lang: string): void {
        Cookies.set(cookieKeys.lang, lang, { expires: 365 })
        location.href = changeLanguageInUrl(lang) + location.search
    }

    return {
        currentLang,
        switchLanguage,
    }
}
