<script setup lang="ts">
import type { PageType } from '@/types/ga'
import GARemarketingEvent from '@/modules/ga/GARemarketingEvent'

type Props = {
    pageType: PageType
}

const { pageType } = defineProps<Props>()

GARemarketingEvent.push({
    ecomm_prodid: '',
    ecomm_pagetype: pageType,
    ecomm_totalvalue: '',
    ecomm_category: [],
})
</script>

<template></template>
