<template>
    <div class="text-sm opacity-80 pt-5">
        {{ $trans.get('common.cart_agree_to_terms') }}

        <a
            href="/docs/privacy-policy"
            target="_blank"
            class="text-main hover:underline"
        >
            {{ $trans.get('common.privacy_policy_2') }}
        </a>

        {{ $trans.get('common.and') }}

        <a
            href="/docs/offer-contract.pdf"
            target="_blank"
            class="text-main hover:underline"
        >
            {{ $trans.get('common.offer_contract_2') }}
        </a>
    </div>
</template>
