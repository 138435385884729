import type { SmallBanner } from '@shared/types/models'
import type { ServerResponse } from '@shared/types/server'
import { ref, onMounted } from 'vue'
import handleServerError from '@shared/modules/handleServerError'
import axios from 'axios'

export default () => {
    const banners = ref<SmallBanner[]>([])
    const loading = ref<boolean>(true)

    onMounted(() => fetchBanners())

    function fetchBanners(): void {
        loading.value = true

        axios.get<ServerResponse<SmallBanner[]>>('/api/v2/banners/small')
            .then(resp => banners.value = resp.data.data)
            .catch(handleServerError)
            .finally(() => loading.value = false)
    }

    return {
        banners,
        loading,
    }
}
