<script setup lang="ts">
import useNewReview from '@/composables/product/useNewReview'
import Modal from '@shared/components/Modal.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import ChooseStars from '@/components/Product/ProductTabs/ChooseStars.vue'
import SolidButton from '@shared/components/Buttons/SolidButton.vue'
import Spinner from '@shared/components/Spinner.vue'
import WhiteButton from '@shared/components/Buttons/WhiteButton.vue'

type Props = {
    productId: number
}

const { productId } = defineProps<Props>()
const inpClass = 'w-full border border-border rounded-md py-2 px-3'

const {
    form,
    loading,
    submitNewReview,
    allowedToSubmit,
    formIsVisible,
} = useNewReview(productId)

function setStars(rate: number) {
    form.value.rate = rate
}
</script>

<template>
    <div class="flex gap-3">
        <white-button @click="formIsVisible = true">
            <i class="lni lni-envelope"></i>
            {{ $trans.get('reviews.leave_a_review')  }}
        </white-button>

        <white-button href="/admin/reviews" v-if="$auth && $auth.is_admin">
            <i class="lni lni-cog"></i>
            {{ $trans.get('reviews.manage_reviews')  }}
        </white-button>
    </div>

    <!-- Review Form Modal -->
    <appear-transition>
        <modal
            v-if="formIsVisible"
            @close="formIsVisible = false"
            :opened="formIsVisible"
            classes="max-w-2xl mx-auto"
        >
            <h2 class="text-xl lg:text-2xl text-center mb-6">
                <i class="lni lni-envelope mr-2" aria-hidden="true"></i>
                {{ $trans.get('reviews.leave_a_review') }}
            </h2>

            <form @submit.prevent="submitNewReview" class="space-y-4">
                <div>
                    <choose-stars
                        @chosen="setStars"
                        :default-rate="form.rate"
                    />
                </div>

                <div>
                    <label for="review-name">
                        {{ $trans.get('reviews.your_name') }}
                    </label>

                    <input
                        id="review-name"
                        type="text"
                        name="name"
                        :placeholder="$trans.get('common.enter') + ' ' + $trans.get('reviews.your_name').toLowerCase()"
                        :class="inpClass"
                        v-model="form.name"
                    />
                </div>

                <div>
                    <label for="review-comment">
                        {{ $trans.get('reviews.your_review') }}
                    </label>

                    <textarea
                        id="review-comment"
                        name="name"
                        :placeholder="$trans.get('common.enter') + ' ' + $trans.get('reviews.your_review').toLowerCase()"
                        :class="inpClass + ' min-h-[150px]'"
                        v-model="form.comment"
                    ></textarea>
                </div>

                <div class="flex items-center gap-5">
                    <solid-button
                        type="submit"
                        :disabled="!allowedToSubmit || loading"
                        :class="{ 'opacity-50 cursor-not-allowed': !allowedToSubmit || loading }"
                    >
                        <i class="lni lni-pencil mr-2"></i>
                        {{ $trans.get('reviews.leave_a_review') }}
                    </solid-button>

                    <div v-if="loading" class="flex items-center gap-4">
                        <spinner />
                        <span>{{ $trans.get('common.processing') }}...</span>
                    </div>
                </div>
            </form>
        </modal>
    </appear-transition>
</template>