<template>
    <button
        type="button"
        class="hover:scale-105 transition-all text-main-orange-hover hover:text-red-600"
    >
        <span class="md:hidden font-bold">
            {{ $trans.get('common.delete') }}
        </span>

        <i class="hidden md:inline lni lni-cross-circle text-2xl" aria-hidden="true"></i>
    </button>
</template>
