<script setup lang="ts">
import type { Category } from '@shared/types/models'
import { computed, ref } from 'vue'
import DropdownHorizontalTransition from '@shared/components/Transitions/DropdownHorizontalTransition.vue'
import Dropdown from '@/components/Navbar/Dropdown/Dropdown.vue'
import linkTo from '@shared/modules/linkTo'

interface Props {
    category: Category
    mainSlug: string
    secondSlug: string
}

const { category, mainSlug, secondSlug } = defineProps<Props>()
const dropdownIsVisible = ref<boolean>(false)

const linkUri = computed<string>(() => {
    let result = `/products/${mainSlug}/${secondSlug}`

    if (category.level === 3) {
        result = `${result}?categories=${category.id}`
    } else if (category.level === 4) {
        result = `${result}?categories=${category.parent_id},${category.id}`
    }

    return result
})

function toggleDropdown(state: boolean): void {
    dropdownIsVisible.value = state
}
</script>

<template>
    <a
        class="px-4 py-3 border-b last:border-none w-full lg:w-[200px] transition-colors hover:bg-gray-100 relative"
        :href="linkTo(linkUri)"
        aria-haspopup="menu"
        @mouseenter="toggleDropdown(true)"
        @mouseleave="toggleDropdown(false)"
    >
        <div class="flex items-center justify-between gap-3">
            <div class="flex items-center gap-3">
                <img
                    v-if="category.icon_uri"
                    :src="`/${category.icon_uri}`"
                    :alt="category.name"
                    width="256"
                    height="256"
                    class="w-6 h-6 rounded-sm"
                />

                <span class="text-main-dark">{{ category.name }}</span>
            </div>

            <i
                v-if="category.categories.length > 0"
                class="lni lni-chevron-right text-xs font-black hidden lg:block"
                aria-hidden="true"
            ></i>
        </div>

        <dropdown-horizontal-transition v-if="dropdownIsVisible && category">
            <dropdown
                v-if="category.categories.length > 0"
                class="!top-[-1px] !left-[calc(100%+20px)] hidden lg:block"
                :categories="category.categories"
                :main-slug="mainSlug"
                :key="category.id"
                :second-slug="secondSlug"
            />
        </dropdown-horizontal-transition>
    </a>
</template>
