<script setup lang="ts">
import type { FilterState } from '@shared/types'
import { ref } from 'vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'
import FilterCard from '@/components/Filters/UI/FilterCard.vue'
import FilterCheckboxOption from '@/components/Filters/UI/FilterCheckboxOption.vue'
import FilterTitle from '@/components/Filters/UI/FilterTitle.vue'

type Props = {
    states: FilterState[]
}

const { states } = defineProps<Props>()
const selectedStates = ref<string[]>([])

setAvailabilities()

function setAvailabilities(): void {
    const url = new URL(window.location.href)
    const states = url.searchParams.get('availability')

    if (!states) {
        return
    }

    selectedStates.value = states.split(',')
}

function submitFilter(): void {
    let url = window.location.href

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        url = replaceQueryArgument('page', '1', url)
    }

    window.location.href = replaceQueryArgument(
        'availability',
        selectedStates.value.join(','),
        url,
    )
}

function handleCheckedStates(state: FilterState, isChecked: boolean): void {
    if (isChecked) {
        selectedStates.value.push(state.name)
    } else {
        selectedStates.value = selectedStates.value.filter(s => s !== state.name)
    }

    submitFilter()
}
</script>

<template>
    <filter-card v-if="states.length !== 0">
        <filter-title>{{ $trans.get('common.availability') }}</filter-title>

        <filter-checkbox-option
            v-for="state in states"
            :key="state.name"
            :is-checked="selectedStates.includes(state.name)"
            :id="`state-${state.name}`"
            @changed="val => handleCheckedStates(state, val)"
        >
            {{ state.title }}
        </filter-checkbox-option>
    </filter-card>
</template>
