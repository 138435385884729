import { CartItem } from '@shared/types'
import { storageKeys } from '@shared/appConfig'

export default class CartCache {
    public static getCartItems(): CartItem[] {
        const cache = localStorage.getItem(storageKeys.cart)

        if (cache) {
            return JSON.parse(cache)
        }

        return []
    }

    public static getCartItem(productSlug: string): CartItem | null {
        const items = this.getCartItems()
        return items.find(item => item.product.slug === productSlug) || null
    }

    public static addCartItem(cartItem: CartItem): void {
        const items = this.getCartItems()

        const oldItem = this.getCartItem(cartItem.product.slug)

        if (oldItem) {
            items.map(item => {
                if (item.product.slug === cartItem.product.slug) {
                    item.quantity = item.quantity + cartItem.quantity
                }

                return item
            })
        } else {
            items.push(cartItem)
        }

        this.save(items)
    }

    public static changeQuantity(productSlug: string, quantity: number): void {
        if (quantity <= 0) {
            return
        }

        const items = this.getCartItems()

        const newItems = items.map(item => {
            if (item.product.slug === productSlug) {
                item.quantity = quantity
            }

            return item
        })

        this.save(newItems)
    }

    public static removeCartItem(productSlug: string): void {
        const items = this.getCartItems()
        const newItems = items.filter(item => item.product.slug !== productSlug)
        this.save(newItems)
    }

    public static clear(): void {
        localStorage.removeItem(storageKeys.cart)
    }

    private static save(items: CartItem[]): void {
        localStorage.setItem(storageKeys.cart, JSON.stringify(items))
    }
}
