<script setup lang="ts">
import type { MinMaxRange } from '@shared/types'
import { ref } from 'vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'
import FilterCard from '@/components/Filters/UI/FilterCard.vue'
import SmallInput from '@/components/Filters/UI/SmallInput.vue'
import RangeSlider from '@/components/Filters/RangeSlider.vue'
import FilterTitle from '@/components/Filters/UI/FilterTitle.vue'

type Props = {
    weightRange: MinMaxRange
}

const { weightRange } = defineProps<Props>()

const selectedRange = ref<MinMaxRange>({
    min: weightRange.min,
    max: weightRange.max,
})

setCurrentRange()

function setCurrentRange(): void {
    const url = new URL(window.location.href)
    const weight = url.searchParams.get('weight')

    if (!weight) {
        return
    }

    const [min, max] = weight.split('-')

    selectedRange.value.min = parseInt(min)
    selectedRange.value.max = parseInt(max)
}

function submitFilter(): void {
    const min = selectedRange.value.min.toString()
    const max = selectedRange.value.max.toString()

    let url = window.location.href

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        url = replaceQueryArgument('page', '1', url)
    }

    window.location.href = replaceQueryArgument('weight', `${min}-${max}`, url)
}
</script>

<template>
    <filter-card v-if="weightRange.max !== 0">
        <filter-title
            >{{ $trans.get('common.weight') }} ({{
                $trans.get('common.kg')
            }})</filter-title
        >

        <div class="flex gap-1.5 items-center">
            <small-input
                :curr-value="selectedRange.min"
                :range="weightRange"
                @changed="val => (selectedRange.min = val)"
            />

            <span class="opacity-50 font-black">—</span>

            <small-input
                :curr-value="selectedRange.max"
                :range="weightRange"
                @changed="val => (selectedRange.max = val)"
            />

            <button
                @click="submitFilter"
                class="bg-page-second py-1 px-4 rounded-md border border-border hover:bg-gray-200"
            >
                OK
            </button>
        </div>

        <div class="mt-7 px-2">
            <range-slider
                :min-prop="weightRange.min"
                :max-prop="weightRange.max"
                :curr-min="selectedRange.min"
                :curr-max="selectedRange.max"
                @changedMin="val => (selectedRange.min = val)"
                @changedMax="val => (selectedRange.max = val)"
                unique-id="weight-slider"
            />
        </div>
    </filter-card>
</template>
