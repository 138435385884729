<script setup lang="ts">
type Props = {
    isChecked: boolean
    id: string
}

type Emits = {
    (e: 'changed', val: boolean): void
}

const emit = defineEmits<Emits>()
const { isChecked, id } = defineProps<Props>()
</script>

<template>
    <div class="flex items-center gap-2.5 py-0.5">
        <input
            type="checkbox"
            :checked="isChecked"
            :id="id"
            @change="val => emit('changed', val.target.checked)"
        />

        <label :for="id" class="text-[1.05em] text-gray-600">
            <slot />
        </label>
    </div>
</template>
