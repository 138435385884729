<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { computed, ref } from 'vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import useAddToWishlist from '@/composables/useAddToWishlist'
import showToast from '@shared/modules/showToast'

type Props = {
    product: Product
}

const { product } = defineProps<Props>()
const { addToWishlist, removeFromWishlist } = useAddToWishlist()

const inWishlist = ref<boolean>(
    (!!product.wishlists_count) && product.wishlists_count > 0
)

const buttonTitle = computed(() => {
    return inWishlist.value
        ? Lang.get('common.delete_from_wishlist')
        : Lang.get('common.add_to_wishlist')
})

const buttonIcon = computed(() => {
    return inWishlist.value ? 'lni-heart-filled' : 'lni-heart'
})

function handleClick(): void {
    if (!Auth) {
        showToast({
            text: Lang.get('common.need_to_be_auth'),
            success: false,
        })

        return
    }

    inWishlist.value = !inWishlist.value
    inWishlist.value ? addToWishlist(product.id) : removeFromWishlist(product.id)
}
</script>

<template>
    <appear-transition>
        <button
            type="button"
            @click.prevent="handleClick"
            :title="buttonTitle"
            class="absolute top-3 right-4"
            :class="inWishlist ? 'text-main-pink' : 'text-gray-500'"
            aria-label="Add to wishlist"
        >
            <i class="lni font-black text-2xl" :class="buttonIcon" aria-hidden="true"></i>
        </button>
    </appear-transition>
</template>
