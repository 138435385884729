<script setup lang="ts">
import LargeButton from '@shared/components/Buttons/LargeButton.vue'
import { events } from '@shared/appConfig'
import dispatchEvent from '@shared/modules/dispatchEvent'

function scrollTo(): void {
    const elem = document.getElementById('product-description')

    if (!elem) return

    elem.scrollIntoView({ behavior: 'smooth' })
    dispatchEvent(events.scrolledToFullDescription)
}
</script>

<template>
    <div @click="scrollTo">
        <large-button icon="lni lni-chevron-down" type="outline" class="w-full">
            {{ $trans.get('common.show_full_description') }}
        </large-button>
    </div>
</template>
