<script setup lang="ts">
import type { Cart } from '@shared/types'
import InfoBlock from '@/components/Cart/InfoBlock/InfoBlock.vue'
import InfoRow from '@/components/Cart/InfoRow.vue'
import InfoList from '@/components/Cart/InfoBlock/InfoList.vue'
import { ref } from 'vue'
import { events } from '@shared/appConfig'
import dispatchEvent from '@shared/modules/dispatchEvent'

type Props = {
    cart: Cart
}

const { cart } = defineProps<Props>()
const deliveryId = ref<number>(cart.delivery.id)

function setDeliveryId(newDeliveryId: number): void {
    deliveryId.value = newDeliveryId
    cart.delivery.id = newDeliveryId
    dispatchEvent(events.cartUpdated)
}
</script>

<template>
    <info-block
        :title="$trans.get('common.delivery_types')"
        icon="lni-delivery"
        :tip="$trans.get('common.delivery_details_on_the_phone')"
        link="/delivery"
        :linkTitle="$trans.get('common.more_about_delivery')"
    >
        <info-list>
            <info-row
                v-for="delivery in cart.delivery.items"
                :key="delivery.id"
            >
                <template #name>
                    <div class="flex gap-3">
                        <img
                            :src="`/${delivery.icon_uri}`"
                            width="50"
                            height="50"
                            class="w-6 h-6 scale-110"
                        />

                        <label :for="`delivery-${delivery.id}`">
                            {{ delivery.name }}
                        </label>
                    </div>
                </template>

                <input
                    type="radio"
                    name="delivery"
                    :value="delivery.id"
                    :id="`delivery-${delivery.id}`"
                    :checked="deliveryId === delivery.id"
                    @change="setDeliveryId(delivery.id)"
                />
            </info-row>
        </info-list>
    </info-block>
</template>
