<script setup lang="ts">
import type { Cart } from '@shared/types'
import dispatchEvent from '@shared/modules/dispatchEvent'
import { events } from '@shared/appConfig'
import InfoBlock from '@/components/Cart/InfoBlock/InfoBlock.vue'
import InfoList from '@/components/Cart/InfoBlock/InfoList.vue'
import LargeButton from '@shared/components/Buttons/LargeButton.vue'
import InfoRow from '@/components/Cart/InfoRow.vue'
import Tip from '@shared/components/Tip.vue'

type Props = {
    cart: Cart
    isLoading: boolean
}

type Emits = {
    (e: 'createOrder'): void
}

const emit = defineEmits<Emits>()
const { cart } = defineProps<Props>()
</script>

<template>
    <info-block :show-buttons="true">
        <info-list>
            <info-row>
                <template #name>{{ $trans.get('common.total_sum') }}</template>
                {{ cart.sum }} {{ $trans.get('common.uah') }}
            </info-row>

            <info-row>
                <template #name>
                    {{ $trans.get('common.delivery') }}
                </template>

                <span>
                    {{ cart.delivery.price }} {{ $trans.get('common.uah') }}
                </span>
            </info-row>

            <info-row>
                <template #name>
                    {{ $trans.get('common.discount_with_cashback') }}

                    <tip
                        :content="$trans.get('order.auth_to_receive_cashback')"
                        :icon="true"
                        class="ml-1"
                    />
                </template>

                <span v-if="cart.cashback.discount ===0 ">
                    0 {{ $trans.get('common.uah') }}
                </span>
                <span v-else class="text-main-green-hover">
                    - {{ cart.cashback.discount }} {{ $trans.get('common.uah') }}
                </span>
            </info-row>

            <info-row>
                <template #name>{{ $trans.get('common.for_pay') }}</template>
                {{ cart.totalSum }} {{ $trans.get('common.uah') }}
            </info-row>
        </info-list>

        <div class="space-y-2 mt-4">
            <large-button
                :icon="isLoading ? undefined : 'lni lni-checkmark'"
                @click="emit('createOrder')"
                class="w-full"
                :class="{ 'opacity-50': isLoading }"
                :disabled="isLoading"
            >
                <span v-if="isLoading">
                    <i class="lni lni-spinner animate-spin mr-3" aria-hidden="true"></i>
                    {{ $trans.get('common.processing') }} ...
                </span>
                <span v-else>{{ $trans.get('common.send_order') }}</span>
            </large-button>

            <large-button
                icon="lni lni-cart"
                @click="dispatchEvent(events.showCheckoutPage, false)"
                class="w-full"
                type="outline"
            >
                {{ $trans.get('common.back_to_cart') }}
            </large-button>
        </div>
    </info-block>
</template>
