<script setup lang="ts">
import type { NavbarLink } from '@shared/types'
import NavLinks from '@/components/Navbar/Menu/NavLinks.vue'
import NavLink from '@/components/Navbar/Menu/NavLink.vue'
import Dropdown from '@/components/Navbar/Dropdown/Dropdown.vue'
import SlideBottomTransition from '@shared/components/Transitions/SlideBottomTransition.vue'
import useNavbarLinks from '@/composables/useNavbarLinks'

const { links, smallLinks } = useNavbarLinks()

function toggleDropdown(state: boolean, link: NavbarLink): void {
    link.dropdownIsOpen = state
}

function handleClick(link: NavbarLink): void {
    link.dropdownIsOpen = !link.dropdownIsOpen
}
</script>

<template>
    <div class="w-full h-full flex flex-col lg:flex-row justify-between items-center">
        <nav-links>
            <nav-link
                v-for="link in links"
                :key="link.title"
                :href="link.href"
                :aria-expanded="!!link.isDropdown"
                :aria-label="`Ссылка на ${link.title}`"
                aria-haspopup="menu"
                @click="link.isDropdown ? handleClick(link) : null"
                @mouseleave="link.isDropdown ? toggleDropdown(false, link) : null"
                :classes="link.isActive ? 'text-main !font-bold bg-page-second' : ''"
            >
                <span class="text-main-dark">{{ link.title }}</span>

                <i
                    v-if="link.isDropdown"
                    class="lni lni-chevron-down ml-1.5 -mb-1 text-xs font-black"
                    aria-hidden="true"
                ></i>

                <slide-bottom-transition>
                    <dropdown
                        v-if="link.dropdownIsOpen && link.isDropdown && link.categories"
                        :categories="link.categories"
                        :main-slug="link.slug"
                    />
                </slide-bottom-transition>
            </nav-link>
        </nav-links>

        <div class="flex flex-row gap-4 lg:gap-6 my-3 lg:my-0 flex-wrap">
            <a
                v-for="link in smallLinks"
                :key="link.title"
                :href="link.href"
                :class="{ 'underline text-main': link.isActive }"
                class="text-[.9rem] font-normal hover:underline"
                aria-label="Navbar link"
            >
                {{ link.title }}
            </a>
        </div>
    </div>
</template>
