<script setup lang="ts">
import type { FilterCategory } from '@shared/types'
import { ref } from 'vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'
import FilterCard from '@/components/Filters/UI/FilterCard.vue'
import FilterCheckboxOption from '@/components/Filters/UI/FilterCheckboxOption.vue'
import FilterTitle from '@/components/Filters/UI/FilterTitle.vue'

type Props = {
    categories: FilterCategory[]
}

const { categories } = defineProps<Props>()
const selectedCategories = ref<number[]>([])

setCategories()

function setCategories(): void {
    const url = new URL(window.location.href)
    const ids = url.searchParams.get('categories')

    if (!ids) {
        return
    }

    const selectedIds = ids.split(',')

    selectedCategories.value = selectedIds.map(id => parseInt(id))
}

function submitFilter(): void {
    let url = window.location.href

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        url = replaceQueryArgument('page', '1', url)
    }

    window.location.href = replaceQueryArgument(
        'categories',
        selectedCategories.value.join(','),
        url,
    )
}

function handleCheckedBrand(id: number, isChecked: boolean): void {
    if (isChecked) {
        selectedCategories.value.push(id)
    } else {
        selectedCategories.value = selectedCategories.value.filter(b => b !== id)
    }

    submitFilter()
}
</script>

<template>
    <filter-card v-if="categories.length !== 0">
        <filter-title>{{ $trans.get('common.categories') }}</filter-title>

        <filter-checkbox-option
            v-for="category in categories"
            :key="category.id"
            :is-checked="selectedCategories.includes(category.id)"
            :id="`brand-${category.id}`"
            @changed="val => handleCheckedBrand(category.id, val)"
        >
            {{ category.name }}
            <small class="text-gray-400 tracking-wide"
                >({{ category.products_count }})</small
            >
        </filter-checkbox-option>
    </filter-card>
</template>
