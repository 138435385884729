<script setup lang="ts">
import { events } from '@shared/appConfig'
import { onMounted, ref } from 'vue'
import listenEvent from '@shared/modules/listenEvent'
import CoinButton from '@/components/Navbar/CoinButton.vue'
import CartCache from '@shared/modules/cart/CartCache'
import CartComponent from '@/components/Cart/Cart.vue'
import useCart from '@/composables/cart/useCart'
import fetchCashback from '@/modules/cart/fetchCashback'
import fetchDeliveries from '@/modules/cart/fetchDeliveries'
import fetchPaymentMethods from '@/modules/cart/fetchPaymentMethods'

const cartIsOpened = ref<boolean>(false)
const cartItemsCount = ref<number>(0)
const { cart } = useCart()

onMounted(() => {
    cartItemsCount.value = CartCache.getCartItems()
        .reduce((sum, item) => sum + item.quantity, 0)
})

listenEvent(events.cartUpdated, () => {
    const items = CartCache.getCartItems()

    if (items.length === 0) {
        cartItemsCount.value = 0
        return
    }

    cartItemsCount.value = items.reduce((sum, item) => sum + item.quantity, 0)
})

listenEvent<boolean>(events.orderSent, _ => {
    cartIsOpened.value = false
    cartItemsCount.value = 0
})

async function openCart() {
    cartIsOpened.value = true

    if (!cartIsOpened.value) {
        return
    }

    cart.value.cashback.user = await fetchCashback()

    if (cart.value.delivery.items.length === 0) {
        cart.value.delivery.items = await fetchDeliveries()
    }

    if (cart.value.paymentMethod.items.length === 0) {
        cart.value.paymentMethod.items = await fetchPaymentMethods()
    }
}
</script>

<template>
    <coin-button
        href="javascript:"
        icon="lni-cart"
        :number="cartItemsCount"
        :title="$trans.get('common.cart')"
        @click="openCart"
    />

    <cart-component
        :opened="cartIsOpened"
        @close="cartIsOpened = false"
        :cart="cart"
    />
</template>
