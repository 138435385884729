<script setup lang="ts">
type Emits = {
    (e: 'clicked'): void
}

type Props = {
    icon: 'lni-chevron-right' | 'lni-chevron-left'
    classes: string
}

const { icon, classes } = defineProps<Props>()

const emit = defineEmits<Emits>()
</script>

<template>
    <button
        @click="emit('clicked')"
        class="absolute top-1/2 -translate-y-1/2 bg-white rounded-full h-6 w-6 lg:h-10 lg:w-10 shadow-lg flex justify-center items-center"
        :class="classes"
    >
        <i class="lni text-xs lg:text-lg font-bold" aria-hidden="true" :class="icon"></i>
    </button>
</template>
