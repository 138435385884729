<script setup lang="ts">
import type { Cart } from '@shared/types'
import InfoBlock from '@/components/Cart/InfoBlock/InfoBlock.vue'
import ToggleSwitch from '@shared/components/ToggleSwitch.vue'
import { ref } from 'vue'
import { events } from '@shared/appConfig'
import dispatchEvent from '@shared/modules/dispatchEvent'

type Props = {
    cart: Cart
}

const props = defineProps<Props>()
const payWithCashback = ref(props.cart.cashback.use)

function togglePayWithCashback(): void {
    payWithCashback.value = !payWithCashback.value
    props.cart.cashback.use = payWithCashback.value
    dispatchEvent(events.cartUpdated)
}
</script>

<template>
    <info-block>
        <div class="flex justify-between gap-3">
            <div>
                <span>{{ $trans.get('common.pay_with_cashback') }}?</span>


                <div class="flex gap-3 items-center mt-6">
                    <toggle-switch
                        @changed="togglePayWithCashback"
                        :initial-value="payWithCashback"
                    />

                    <b v-if="payWithCashback" class="text-main">{{ $trans.get('common.yes') }}</b>
                    <b v-else class="text-gray-500">{{ $trans.get('common.no') }}</b>
                </div>
            </div>

            <div class="text-right">
                <b>
                    {{ props.cart.cashback.user }} {{ $trans.get('common.uah') }}
                </b>

                <div
                    v-if="props.cart.cashback.discount > 0"
                    class="flex flex-col"
                >
                    <b class="text-main-pink">
                        - {{ props.cart.cashback.discount }} {{ $trans.get('common.uah') }}
                    </b>

                    <b class="text-main-green-hover">
                        {{ props.cart.cashback.user - props.cart.cashback.discount }} {{ $trans.get('common.uah') }}
                    </b>
                </div>
            </div>
        </div>
    </info-block>
</template>
