<script setup lang="ts">
function moveToTop(): void {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}
</script>

<template>
    <button
        @click="moveToTop"
        type="button"
        class="inline-flex items-center gap-5 border border-gray-600 px-6 py-2 rounded-md transition-colors hover:border-gray-400 hover:text-white"
    >
        <i class="lni lni-angle-double-up" aria-hidden="true"></i>
        {{ $trans.get('common.to_top') }}
    </button>
</template>
