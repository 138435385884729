<script setup lang="ts">
import StatusPageUi from '@/components/Ui/StatusPageUi.vue'
import useStatusPage from '@/composables/payment/useStatusPage'
import Spinner from '@shared/components/Spinner.vue'
import SolidButton from '@shared/components/Buttons/SolidButton.vue'

type Props = {
    orderToken: string
}

const { orderToken } = defineProps<Props>()
const { order, loading } = useStatusPage(orderToken)
</script>

<template>
    <spinner v-if="loading" />

    <status-page-ui v-else>
        <template #image>
            <img
                v-if="order && order.payment"
                :src="`/storage/payment/${order.payment.status}.svg`"
                :alt="order.payment.status"
            />

            <img
                v-else
                src="/storage/payment/error.svg"
                alt="Payment error"
            />
        </template>

        <template #title>
            <span v-if="order && order.payment">
                {{ $trans.get(`order.payment_status.title.${order.payment.status}`) }}
            </span>
            <span v-else>
                {{ $trans.get('order.unknown_payment_issue') }}
            </span>
        </template>

        <template #description>
            <span v-if="order && order.payment">
                {{ $trans.get(`order.payment_status.description.${order.payment.status}`) }}
            </span>
            <span v-else>
                {{ $trans.get('order.unknown_payment_issue_desc') }}
            </span>
        </template>

        <template #actions>
            <solid-button
                href="/"
                icon="lni-home"
            >
                {{ $trans.get('common.go_back_home') }}
            </solid-button>
        </template>
    </status-page-ui>
</template>
