<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    href?: string | null
    classes?: string
}

const { href, classes = '' } = defineProps<Props>()

const className = computed<string>(() => {
    let result =
        'transition-colors font-light leading-5 lg:border-r last:border-r-0 hover:bg-page-second h-full w-full lg:w-auto flex items-center py-4 px-3 xl:px-6 text-md relative'

    if (classes) {
        result += ` ${classes}`
    }

    return result
})
</script>

<template>
    <a v-if="href" :href :class="className" aria-label="Navbar link">
        <slot />
    </a>
    <span v-else :class="className">
        <slot />
    </span>
</template>
