<script setup lang="ts">
import type { CheckoutFormData } from '@shared/types'

type Props = {
    formData: CheckoutFormData
}

const { formData } = defineProps<Props>()

const inputStyles = 'w-full border border-border rounded-md py-2 px-3'
const labelStyles = 'block text-sm font-bold text-gray-600 mb-1'
</script>

<template>
    <form>
        <div class="space-y-5">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label for="name" :class="labelStyles">
                        * {{ $trans.get('common.name') }}
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        v-model="formData.name"
                        :placeholder="$trans.get('common.example') + ': Анна Корочаева'"
                        :class="inputStyles"
                    />
                </div>

                <div>
                    <label for="phone" :class="labelStyles">
                        * {{ $trans.get('common.phone') }}
                    </label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        v-model="formData.phone"
                        :placeholder="$trans.get('common.example') + ': +30956647000'"
                        :class="inputStyles"
                    />
                </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label for="city" :class="labelStyles">
                        {{ $trans.get('common.city') }}
                    </label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        v-model="formData.city"
                        :placeholder="$trans.get('common.example_city')"
                        :class="inputStyles"
                    />
                </div>

                <div>
                    <label for="email" :class="labelStyles">
                        {{ $trans.get('common.email') }}
                    </label>
                    <input
                        type="text"
                        id="email"
                        name="email"
                        v-model="formData.email"
                        :placeholder="$trans.get('common.example') + ': anna@mail.com'"
                        :class="inputStyles"
                    />
                </div>
            </div>

            <div>
                <label for="address" :class="labelStyles">
                    {{ $trans.get('common.address') }}
                </label>
                <input
                    type="text"
                    id="address"
                    name="address"
                    v-model="formData.address"
                    :placeholder="$trans.get('common.example_address')"
                    :class="inputStyles"
                />
            </div>

            <div>
                <label for="comment" :class="labelStyles">
                    {{ $trans.get('common.order_comment') }}
                </label>
                <textarea
                    id="comment"
                    name="comment"
                    v-model="formData.comment"
                    :class="inputStyles"
                    class="min-h-[150px]"
                ></textarea>
            </div>
        </div>
    </form>
</template>
