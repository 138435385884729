<script setup lang="ts">
import type { SocialContact } from '@shared/types'
import { ref } from 'vue'
import { events } from '@shared/appConfig'
import listenEvent from '@shared/modules/listenEvent'
import NavMenu from '@/components/Navbar/Menu/NavMenu.vue'
import Contacts from '@/components/Contacts.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import Search from '@/components/Navbar/Search.vue'
import AppButton from '@/components/Navbar/AppButton.vue'

defineProps<{
    contacts: SocialContact[]
}>()

const menuIsVisible = ref(false)

listenEvent<boolean>(events.hamburgerClicked, open => {
    if (open) {
        menuIsVisible.value = true
        return
    }

    menuIsVisible.value = !menuIsVisible.value
})
</script>

<template>
    <appear-transition>
        <nav
            class="hidden lg:block bg-page shadow-2xl lg:shadow-md pb-10 lg:pb-0 z-10"
            :class="{ '!block': menuIsVisible }"
        >
            <div class="container">
                <div class="flex flex-col lg:flex-row justify-between items-center">
                    <div class="lg:hidden relative my-5 w-full">
                        <search />
                    </div>

                    <div class="flex divide-x relative w-full">
                        <nav-menu />
                    </div>

                    <contacts :contacts class="lg:hidden" />

                    <div class="lg:hidden space-y-4 mt-3">
                        <app-button
                            icon="lni-play-store"
                            platform="Google Play"
                            url="https://play.google.com/store/apps/details?id=l.chernenkiy.zoozoozoo"
                        />

                        <app-button
                            icon="lni-apple"
                            platform="App Store"
                            url="https://apps.apple.com/ua/app/zoozoozoo/id1665730663"
                        />
                    </div>
                </div>
            </div>
        </nav>
    </appear-transition>
</template>
