import type { ServerResponse } from '@shared/types/server'
import { onMounted, ref } from 'vue'
import axios from 'axios'

export default (productId: number) => {
    const productInWishlist = ref<boolean | null>(null)

    onMounted(() => checkIfProductInWishlist())

    function checkIfProductInWishlist(): void {
        if (!Auth) {
            productInWishlist.value = false
            return
        }

        const params = {
            product_id: productId,
            token: Auth.token,
        }

        axios.post<ServerResponse<boolean>>('/api/v2/wishlist/in-wishlist', params)
            .then(resp => productInWishlist.value = resp.data.data)
            .catch(err => console.error(err))
    }

    return {
        productInWishlist,
    }
}
