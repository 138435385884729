import type { SmallNavbarLink, NavbarLink } from '@shared/types'
import type { ServerResponse } from '@shared/types/server'
import type { Category } from '@shared/types/models'
import { onMounted, ref } from 'vue'
import showToast from '@shared/modules/showToast'
import isPath from '@shared/modules/isPath'
import axios from 'axios'
import linkTo from '@shared/modules/linkTo'

export default () => {
    const links = ref<NavbarLink[]>([
        {
            title: Lang.get('common.main'),
            href: isPath('/') ? null : linkTo('/'),
            slug: 'main',
            isDropdown: false,
            isActive: window.location.pathname === linkTo('/'),
        },
        {
            title: Lang.get('common.products'),
            href: isPath('/categories') ? null : linkTo('categories'),
            isDropdown: false,
            slug: 'products',
            isActive:
                window.location.pathname === linkTo('categories') ||
                window.location.pathname === linkTo('products'),
        },
    ])

    const smallLinks: SmallNavbarLink[] = [
        {
            title: Lang.get('common.about_us'),
            href: linkTo('about'),
            isActive: window.location.pathname === linkTo('about'),
        },
        {
            title: Lang.get('common.delivery_and_payment'),
            href: linkTo('delivery'),
            isActive: window.location.pathname === linkTo('delivery'),
        },
        {
            title: Lang.get('common.contacts'),
            href: linkTo('contacts'),
            isActive: window.location.pathname === linkTo('contacts'),
        },
    ]

    onMounted(() => {
        fetchDropdownItems()
    })

    function fetchDropdownItems(): void {
        axios
            .get<ServerResponse<Category[]>>('/api/v2/categories/nested', {
                params: { lang: Locale },
            })
            .then(resp => {
                const data = resp.data

                if (data.status === 'error') {
                    showToast({
                        text: data.message || Lang.get('common.server_error'),
                        success: false,
                    })
                    return
                }

                const newLinks: NavbarLink[] = data.data.map(c => {
                    return {
                        title: c.name,
                        href: 'javascript:',
                        isDropdown: c.categories.length > 0,
                        slug: c.slug,
                        isActive: false,
                        categories: c.categories,
                        dropdownIsOpen: false,
                    }
                })

                links.value.push(...newLinks)
            })
            .catch(err => {
                console.error(err)
                showToast({ text: Lang.get('common.server_error'), success: false })
            })
    }

    return {
        links,
        smallLinks,
    }
}
