<script setup lang="ts">
import useCarousel from '@/composables/home/useCarousel'
import CarouselButton from '@/components/Banner/CarouselButton.vue'
import SkeletonLoading from '@/components/Banner/SkeletonLoading.vue'
import EditBannerButton from '@/components/Banner/EditBannerButton.vue'
import CarouselImage from '@/components/Banner/CarouselImage.vue'

const {
    track,
    slides,
    loading,
    moveSlideTo,
    restartSlider,
    stopSlider,
    prevSlide,
    moveSlide,
    activeIndex,
} = useCarousel()
</script>

<template>
    <div class="flex w-full relative group">
        <skeleton-loading v-if="loading" />

        <carousel-image
            v-else-if="slides.length === 0"
            src="/storage/wallpaper.png"
        />

        <ul
            ref="track"
            class="w-full h-[250px] sm:h-[380px] md:h-[306px] lg:h-[420px] xl:h-[530px] 2xl:h-[640px] 3xl:h-[715px] relative"
        >
            <li
                v-for="slide in slides"
                :key="slide.url"
                class="absolute inset-0 rounded-md shadow-md transition-all ease-in-out"
                :style="{ left: slide.isActive ? '0' : '100%' }"
            >
                <carousel-image
                    :src="slide.url"
                    @mouseenter="stopSlider"
                    @mouseleave="restartSlider"
                />
            </li>
        </ul>

        <edit-banner-button href="/admin/banners#main-slider" />

        <div
            v-if="slides.length > 1"
            class="opacity-100 lg:opacity-0 group-hover:opacity-100 transition-opacity"
        >
            <carousel-button
                @clicked="prevSlide"
                icon="lni-chevron-left"
                classes="left-2 lg:left-5"
                aria-label="Move to previous slide"
            />

            <carousel-button
                @clicked="moveSlide"
                icon="lni-chevron-right"
                classes="right-2 lg:right-5"
                aria-label="Move to the next slide"
            />

            <div
                class="flex justify-center items-center absolute bottom-3 lg:bottom-5 left-1/2 -translate-x-1/2"
            >
                <button
                    type="button"
                    v-for="(slide, index) in slides"
                    :key="slide.url"
                    @click="moveSlideTo(index)"
                    class="h-1 lg:h-2 w-5 mx-1 cursor-pointer hover:scale-110 transition-transform shadow-sm rounded-full"
                    :class="activeIndex === index ? 'bg-white' : 'bg-black/30'"
                ></button>
            </div>
        </div>
    </div>
</template>
