import type { MainSliderImage, HomeSlide } from '@shared/types'
import type { ServerResponse } from '@shared/types/server'
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

export default () => {
    const slides = ref<HomeSlide[]>([])
    const loading = ref<boolean>(true)
    const intervalId = ref<number | null>(null)
    const track = ref<HTMLElement | null>(null)
    const activeIndex = ref<number>(0)
    const slideTimeout = 5000

    onMounted(() => fetchSlides())

    intervalId.value = window.setInterval(moveSlide, slideTimeout)

    function fetchSlides(): void {
        loading.value = true

        axios.get<ServerResponse<MainSliderImage[]>>('/api/v2/banners/main-slider')
            .then(resp => slides.value = resp.data.data.map(setSlide))
            .catch(handleServerError)
            .finally(() => loading.value = false)
    }

    function setSlide(image: MainSliderImage, index: number): HomeSlide {
        return { url: image.url, isActive: index === 0 }
    }

    function resetSlidesToStart(): void {
        activeIndex.value = 0

        slides.value = slides.value.map((slide, i) => {
            slide.isActive = i === 0
            return slide
        })
    }

    function resetSlidesToEnd(): void {
        const lastSlideIndex = slides.value.length - 1
        activeIndex.value = lastSlideIndex

        slides.value = slides.value.map((slide, i) => {
            slide.isActive = i === lastSlideIndex
            return slide
        })
    }

    function moveSlide(): void {
        const activeSlideIndex = slides.value.findIndex(slide => slide.isActive)
        const nextSlideIndex = activeSlideIndex + 1

        if (!slides.value[nextSlideIndex]) {
            resetSlidesToStart()
            return
        }

        activeIndex.value = nextSlideIndex
        slides.value[nextSlideIndex].isActive = true
        slides.value[activeSlideIndex].isActive = false
    }

    function prevSlide(): void {
        const activeSlideIndex = slides.value.findIndex(slide => slide.isActive)
        const prevSlideIndex = activeSlideIndex - 1

        console.log({ activeSlideIndex })

        if (!slides.value[prevSlideIndex]) {
            resetSlidesToEnd()
            return
        }

        activeIndex.value = prevSlideIndex
        slides.value[prevSlideIndex].isActive = true
        slides.value[activeSlideIndex].isActive = false
    }

    function moveSlideTo(index: number): void {
        activeIndex.value = index

        slides.value = slides.value.map((slide, i) => {
            slide.isActive = i === index
            return slide
        })
    }

    function stopSlider(): void {
        if (intervalId.value !== null) {
            window.clearInterval(intervalId.value)
            intervalId.value = null
        }
    }

    function restartSlider(): void {
        if (intervalId.value === null) {
            intervalId.value = window.setInterval(moveSlide, slideTimeout)
        }
    }

    return {
        track,
        slides,
        loading,
        moveSlideTo,
        stopSlider,
        restartSlider,
        moveSlide,
        prevSlide,
        activeIndex,
    }
}
