<script setup lang="ts">
import type { Order } from '@shared/types/models'
import { events } from '@shared/appConfig'
import dispatchEvent from '@shared/modules/dispatchEvent'
import GAProductEvent from '@/modules/ga/GAProductEvent'
import CartCache from '@shared/modules/cart/CartCache'
import useGaEvents from '@/composables/cart/useGaEvents'

type Props = {
    order: Order
}

const { order } = defineProps<Props>()
const { gaRemarketingEvent } = useGaEvents()

const cartItems = CartCache.getCartItems()
gaRemarketingEvent('purchase', cartItems, order.total_sum)

GAProductEvent.push({
    event: 'purchase',
    ecommerce: {
        transaction_id: order.id,
        affiliation: 'cart',
        value: order.total_sum.toFixed(2),
        tax: 0,
        shipping: 0,
        currency: 'UAH',

        items: cartItems.map(cartItem => {
            return {
                item_name: cartItem.product.title,
                item_id: cartItem.product.id,
                item_variant: cartItem.product.measure_label,
                price: cartItem.product.price.toFixed(2),
                quantity: cartItem.quantity,
                ...GAProductEvent.getCategories(cartItem.product),
            }
        })
    },
})

CartCache.clear()

dispatchEvent(events.orderSent, true)
dispatchEvent(events.cartUpdated)
</script>

<template></template>
