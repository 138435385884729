<template>
    <div class="prose max-w-none">
        <p>Повернути або обміняти товар можна протягом 14 днів з моменту покупки за наступних умов:</p>

        <ul>
            <li>збережений товарний вигляд;</li>
            <li>товар не був в експлуатації;</li>
            <li>збережені всі його споживчі властивості, а також бірка, пломба, етикетка, тощо;</li>
            <li>збережений товарний чек про покупку;</li>
            <li>товар не входить до переліку товарів, що не підлягають обміну та поверненню.</li>
        </ul>

        <p>Детальніше про обмін читайте в <a href="/docs/return-policy" target="_blank">цьому розділі</a></p>
    </div>
</template>