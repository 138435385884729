<script setup lang="ts">
import type { PaymentMethod } from '@shared/types/models'
import type { Cart } from '@shared/types'
import InfoBlock from '@/components/Cart/InfoBlock/InfoBlock.vue'
import InfoRow from '@/components/Cart/InfoRow.vue'
import InfoList from '@/components/Cart/InfoBlock/InfoList.vue'
import { ref } from 'vue'
import { events } from '@shared/appConfig'
import dispatchEvent from '@shared/modules/dispatchEvent'

type Props = {
    cart: Cart
}

const { cart } = defineProps<Props>()
const paymentMethodSlug = ref<PaymentMethod>(cart.paymentMethod.slug || cart.paymentMethod.items[0].slug)

function setPaymentMethodSlug(slug: PaymentMethod): void {
    paymentMethodSlug.value = slug
    cart.paymentMethod.slug = slug
    dispatchEvent(events.cartUpdated)
}
</script>

<template>
    <info-block
        :title="$trans.get('order.payment_method.title')"
        icon="lni-wallet"
        link="/delivery"
        :linkTitle="$trans.get('common.more_about_payment')"
    >
        <info-list>
            <info-row
                v-for="method in cart.paymentMethod.items"
                :key="method.slug"
            >
                <template #name>
                    <label :for="`method-${method.slug}`">
                        {{ method.title }}
                    </label>
                </template>

                <input
                    type="radio"
                    name="method"
                    :value="method.slug"
                    :id="`method-${method.slug}`"
                    :checked="paymentMethodSlug === method.slug"
                    @change="setPaymentMethodSlug(method.slug)"
                />
            </info-row>
        </info-list>
    </info-block>
</template>
