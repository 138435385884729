import firebase from 'firebase/app'
import 'firebase/auth'

firebase.initializeApp({
    apiKey: "AIzaSyBTwSVmUjCMRydibxELucOPcl0t_VZTCK0",
    authDomain: "zoo-zoo-zoo.firebaseapp.com",
    projectId: "zoo-zoo-zoo",
    storageBucket: "zoo-zoo-zoo.appspot.com",
    messagingSenderId: "357464640331",
    appId: "1:357464640331:web:f128fcac14b14face2f6ce",
    measurementId: "G-VNL68873NN"
})

export default firebase
