<script setup lang="ts">
import type { MinMaxRange } from '@shared/types'
import { ref } from 'vue'
import FilterCard from '@/components/Filters/UI/FilterCard.vue'
import SmallInput from '@/components/Filters/UI/SmallInput.vue'
import RangeSlider from '@/components/Filters/RangeSlider.vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'
import FilterTitle from '@/components/Filters/UI/FilterTitle.vue'

type Props = {
    priceRange: MinMaxRange
}

const { priceRange } = defineProps<Props>()

const selectedRange = ref<MinMaxRange>({
    min: priceRange.min,
    max: priceRange.max,
})

setCurrentRange()

function setCurrentRange(): void {
    const url = new URL(window.location.href)
    const price = url.searchParams.get('price')

    if (!price) {
        return
    }

    const [min, max] = price.split('-')

    selectedRange.value.min = parseInt(min)
    selectedRange.value.max = parseInt(max)
}

function submitFilter(): void {
    const min = selectedRange.value.min.toString()
    const max = selectedRange.value.max.toString()
    let url = window.location.href

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        url = replaceQueryArgument('page', '1', url)
    }

    window.location.href = replaceQueryArgument('price', `${min}-${max}`, url)
}
</script>

<template>
    <filter-card>
        <filter-title>{{ $trans.get('common.price') }}</filter-title>

        <h3 v-if="priceRange.max === 0">
            {{ $trans.get('common.filter_is_not_available') }}
        </h3>

        <div v-else>
            <div class="flex gap-1.5 items-center">
                <small-input
                    :curr-value="selectedRange.min"
                    :range="priceRange"
                    @changed="val => (selectedRange.min = val)"
                />

                <span class="opacity-50 font-black">—</span>

                <small-input
                    :curr-value="selectedRange.max"
                    :range="priceRange"
                    @changed="val => (selectedRange.max = val)"
                />

                <button
                    @click="submitFilter"
                    class="bg-page-second py-1 px-4 rounded-md border border-border hover:bg-gray-200"
                >
                    OK
                </button>
            </div>

            <div class="mt-7 px-2">
                <range-slider
                    :min-prop="priceRange.min"
                    :max-prop="priceRange.max"
                    :curr-min="selectedRange.min"
                    :curr-max="selectedRange.max"
                    unique-id="price-slider"
                    @changedMin="val => (selectedRange.min = val)"
                    @changedMax="val => (selectedRange.max = val)"
                />
            </div>
        </div>
    </filter-card>
</template>
