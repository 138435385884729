export default (path: string) => {
    if (path === '/') {
        path = ''
    }

    const uris = [path, `/ru${path}`, `/en${path}`]

    let current = window.location.pathname

    if (current[current.length - 1] === '/') {
        current = current.slice(0, -1)
    }

    return uris.includes(current)
}
