import type { Product } from '@shared/types/models'
import { onMounted, ref } from 'vue'
import { events } from '@shared/appConfig'
import listenEvent from '@shared/modules/listenEvent'

export default (product: Product) => {
    const tabs = ref([
        {
            title: Lang.get('common.about_the_product'),
            badge: null,
            hash: '#about',
        },
        {
            title: Lang.get('common.delivery_and_payment'),
            badge: null,
            hash: '#delivery',
        },
        {
            title: Lang.get('common.return'),
            badge: null,
            hash: '#return',
        },
        {
            title: Lang.get('reviews.reviews'),
            badge: product.reviews_count,
            hash: '#reviews',
        },
    ])

    const currTab = ref<number>(0)

    onMounted(() => {
        setTabOnLoad()

        listenEvent(events.scrolledToFullDescription, () => {
            changeTab(tabs.value.findIndex(tab => tab.hash === '#about'))
            scrollToTab()
        })

        listenEvent(events.scrolledToReviews, () => {
            changeTab(tabs.value.findIndex(tab => tab.hash === '#reviews'))
            scrollToTab()
        })
    })

    function setTabOnLoad() {
        const currHash = window.location.hash
        const matchTab = tabs.value.find(tab => tab.hash === currHash)

        if (!matchTab)
            return

        currTab.value = tabs.value.indexOf(matchTab)

        scrollToTab()
    }

    function scrollToTab() {
        const scrollTo = document.getElementById('product-description')

        if (!scrollTo)
            return

        scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    function changeTab(i: number) {
        const tab = tabs.value[i]

        if (!tab)
            return

        window.location.hash = tab.hash
        currTab.value = i
    }

    return {
        tabs,
        currTab,
        changeTab,
    }
}