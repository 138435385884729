import type { PaginatorServerResponse } from '@shared/types/server'
import type { Review } from '@shared/types/models'
import { onMounted, ref } from 'vue'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

export default (productId: number) => {
    const reviews = ref<Review[]>([])
    const loading = ref<boolean>(false)
    const nextPageUrl = ref<string | null>(null)
    const url = ref<string>('/api/v2/reviews')

    onMounted(() => fetchReviews(true))

    function fetchReviews(refresh: boolean): void {
        if (loading.value) {
            return
        }

        if (refresh) {
            loading.value = true
        }

        const params = {
            product_id: productId,
            lang: Locale,
        }

        axios.get<PaginatorServerResponse<Review[]>>(url.value, { params })
            .then(resp => {
                nextPageUrl.value = resp.data.data.next_page_url

                reviews.value = refresh
                    ? resp.data.data.data
                    : [...reviews.value, ...resp.data.data.data]
            })
            .catch(handleServerError)
            .finally(() => loading.value = false)
    }

    function loadMore(): void {
        if (!nextPageUrl.value) {
            return
        }

        url.value = nextPageUrl.value
        fetchReviews(false)
    }

    return {
        reviews,
        loading,
        nextPageUrl,
        loadMore,
    }
}
