<script setup lang="ts">
import type { Cart } from '@shared/types'
import dispatchEvent from '@shared/modules/dispatchEvent'
import { events } from '@shared/appConfig'
import InfoBlock from '@/components/Cart/InfoBlock/InfoBlock.vue'
import LargeButton from '@shared/components/Buttons/LargeButton.vue'
import InfoRow from '@/components/Cart/InfoRow.vue'

type Props = {
    cart: Cart
}

const props = defineProps<Props>()
</script>

<template>
    <info-block>
        <info-row>
            <template #name>{{ $trans.get('common.total_sum') }}</template>
            {{ props.cart.sum }} {{ $trans.get('common.uah') }}
        </info-row>

        <div class="mt-2">
            <large-button
                icon="lni lni-chevron-right"
                @click="dispatchEvent(events.showCheckoutPage, true)"
                class="w-full"
            >
                {{ $trans.get('common.continue') }}
            </large-button>
        </div>
    </info-block>
</template>
