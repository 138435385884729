<template>
    <div class="w-full relative cursor-pointer h-1 bg-gray-200 rounded-xl mb-3">
        <div :class="`track-${this.uniqueId}`" ref="_vpcTrack"></div>

        <div
            :class="`track-highlight-${this.uniqueId} block absolute w-full h-full bg-main`"
            ref="trackHighlight"
        ></div>

        <button :class="`track-btn track1-${this.uniqueId}`" ref="track1"></button>
        <button :class="`track-btn track2-${this.uniqueId}`" ref="track2"></button>
    </div>
</template>

<script>
export default {
    props: {
        minProp: {
            type: Number,
            required: true,
        },
        maxProp: {
            type: Number,
            required: true,
        },
        currMin: {
            type: Number,
            required: true,
        },
        currMax: {
            type: Number,
            required: true,
        },
        uniqueId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            min: 0,
            max: 0,
            minValue: 0,
            maxValue: 0,
            step: 1,
            totalSteps: 0,
            percentPerStep: 1,
            trackWidth: null,
            isDragging: false,
            pos: {
                curTrack: null
            }
        }
    },

    methods: {
        moveTrack(track, ev) {
            let percentInPx = this.getPercentInPx();

            let trackX = Math.round(this.$refs._vpcTrack.getBoundingClientRect().left);
            let clientX = ev.clientX;
            let moveDiff = clientX - trackX;

            let moveInPct = moveDiff / percentInPx

            if (moveInPct < 1 || moveInPct > 100)
                return;

            let value = (Math.round(moveInPct / this.percentPerStep) * this.step) + this.min;

            if (track === 'track1') {
                if (value >= (this.maxValue - this.step))
                    return;

                this.minValue = value;

                this.$emit('changedMin', value)
            }

            if (track === 'track2') {
                if (value <= (this.minValue + this.step))
                    return;

                this.maxValue = value;

                this.$emit('changedMax', value)
            }

            this.$refs[track].style.left = moveInPct + '%';
            this.setTrackHighlight()

        },

        mousedown(ev, track) {
            if (this.isDragging) return;
            this.isDragging = true;
            this.pos.curTrack = track;
        },

        touchstart(ev, track) {
            this.mousedown(ev, track)
        },

        mouseup(ev, track) {
            if (!this.isDragging) return;
            this.isDragging = false
        },

        touchend(ev, track) {
            this.mouseup(ev, track)
        },

        mousemove(ev, track) {
            if (!this.isDragging) return;
            this.moveTrack(track, ev)
        },

        touchmove(ev, track) {
            this.mousemove(ev.changedTouches[0], track)
        },

        valueToPercent(value) {
            return ((value - this.min) / this.step) * this.percentPerStep
        },

        setTrackHighlight() {
            this.$refs.trackHighlight.style.left = this.valueToPercent(this.minValue) + '%'
            this.$refs.trackHighlight.style.width = (this.valueToPercent(this.maxValue) - this.valueToPercent(this.minValue)) + '%'
        },

        getPercentInPx() {
            let trackWidth = this.$refs._vpcTrack.offsetWidth;
            let oneStepInPx = trackWidth / this.totalSteps;
            // 1 percent in px
            let percentInPx = oneStepInPx / this.percentPerStep;

            return percentInPx;
        },

        setClickMove(ev) {
            let track1Left = this.$refs.track1.getBoundingClientRect().left;
            let track2Left = this.$refs.track2.getBoundingClientRect().left;

            if (ev.clientX < track1Left) {
                this.moveTrack('track1', ev)
            } else if ((ev.clientX - track1Left) < (track2Left - ev.clientX)) {
                this.moveTrack('track1', ev)
            } else {
                this.moveTrack('track2', ev)
            }
        }
    },

    mounted() {
        this.min = this.minProp;
        this.max = this.maxProp;
        this.minValue = this.currMin;
        this.maxValue = this.currMax;

        // calc per step value
        this.totalSteps = (this.max - this.min) / this.step;

        // percent the track button to be moved on each step
        this.percentPerStep = 100 / this.totalSteps;

        // set track1 initial
        document.querySelector(`.track1-${this.uniqueId}`).style.left = this.valueToPercent(this.minValue) + '%'
        // track2 initial position
        document.querySelector(`.track2-${this.uniqueId}`).style.left = this.valueToPercent(this.maxValue) + '%'
        // set initial track highlight
        this.setTrackHighlight()

        var self = this;

        ['mouseup', 'mousemove'].forEach(type => {
            document.body.addEventListener(type, (ev) => {
                // ev.preventDefault();
                if (self.isDragging && self.pos.curTrack) {
                    self[type](ev, self.pos.curTrack)
                }
            })
        });

        const events = ['mousedown', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend']

        events.forEach(type => {
            document.querySelector(`.track1-${this.uniqueId}`).addEventListener(type, (ev) => {
                ev.stopPropagation();
                self[type](ev, 'track1')
            })

            document.querySelector(`.track2-${this.uniqueId}`).addEventListener(type, (ev) => {
                ev.stopPropagation();
                self[type](ev, 'track2')
            })
        })

        // on track click
        // determine direction based on click proximity
        // determine percent to move based on track.clientX - click.clientX
        document.querySelector(`.track-${this.uniqueId}`).addEventListener('click', function (ev) {
            ev.stopPropagation();
            self.setClickMove(ev)
        })

        document.querySelector(`.track-highlight-${this.uniqueId}`).addEventListener('click', function (ev) {
            ev.stopPropagation();
            self.setClickMove(ev)
        })
    }
};
</script>

<style>
.track-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    display: block;
    position: absolute;
    margin-top: -3px;
    z-index: 2;
    width: 1.5rem;
    height: 1.5rem;
    top: calc(-50% - 0.25rem);
    margin-left: -1rem;
    background-color: #eee;
    border: 1px solid #b5b5b5;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    border-radius: 50%;
    transition: box-shadow .3s ease-out, background-color .3s ease, -webkit-transform .3s ease-out;
    transition: transform .3s ease-out, box-shadow .3s ease-out, background-color .3s ease;
    transition: transform .3s ease-out, box-shadow .3s ease-out, background-color .3s ease, -webkit-transform .3s ease-out;
}
</style>

