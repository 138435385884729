import type { ServerResponse } from '@shared/types/server'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

export default async (): Promise<number> => {
    if (!Auth) {
        return 0
    }

    const params = {
        token: Auth.token,
        lang: Locale,
    }

    try {
        const response = await axios.get<ServerResponse<number>>('/api/v2/user/cashback', {
            params,
        })

        return (response.data.data || 0) / 100
    } catch (err) {
        handleServerError(err)
    }

    return 0
}
