<script setup lang="ts">
import GARemarketingEvent from '@/modules/ga/GARemarketingEvent'

type Props = {
    productsIds: number[]
    categoryName1: string
    categoryName2: string
}

const { productsIds, categoryName1, categoryName2 } = defineProps<Props>()

GARemarketingEvent.push({
    ecomm_prodid: productsIds,
    ecomm_pagetype: 'category',
    ecomm_totalvalue: '',
    ecomm_category: [categoryName1, categoryName2],
})
</script>

<template></template>
