<template>
    <div class="prose max-w-none">
        <p>Максимальний термін відправлення замовлення становить 3 робочих дні з моменту обробки замовлення. Замовлення надсилаємо у той самий день, за умови, що його оформили до 16:00 та обробили до 18:00.</p>

        <h3>Поштові служби:</h3>

        <ul>
            <li>Доставка Новою Поштою (1-2 днів);</li>
            <li>Доставка Укрпоштою (3-5 днів).</li>
        </ul>


        <h3>Способи оплати</h3>


        <p>Оплатити замовлення можна:</p>
        <ul>
            <li>Карткою Visa або Mastercard на сайті</li>
            <li>За допомогою платіжних систем Apple Pay або Google Pay</li>
        </ul>

        <p>Оплата при отриманні (накладений платіж або кур’єр ZOO ZOO ZOO MARKET)</p>

        <p>Детальніше про доставку та оплату читайте в <a href="/delivery" target="_blank">цьому розділі</a></p>
    </div>
</template>