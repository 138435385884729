<script setup lang="ts">
import { ref } from 'vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'
import FilterCard from '@/components/Filters/UI/FilterCard.vue'
import FilterCheckboxOption from '@/components/Filters/UI/FilterCheckboxOption.vue'

type Props = {
    measureNames: string[]
}

const { measureNames } = defineProps<Props>()

const selectedNames = ref<string[]>([])

setMeasureNames()

function setMeasureNames(): void {
    const url = new URL(window.location.href)
    const names = url.searchParams.get('measure_names')

    if (!names) {
        return
    }

    selectedNames.value = names.split(',')
}

function submitFilter(): void {
    let url = window.location.href

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        url = replaceQueryArgument('page', '1', url)
    }

    window.location.href = replaceQueryArgument(
        'measure_names',
        selectedNames.value.join(','),
        url,
    )
}

function handleCheckedName(name: string, isChecked: boolean): void {
    if (isChecked) {
        selectedNames.value.push(name)
    } else {
        selectedNames.value = selectedNames.value.filter(n => n !== name)
    }

    submitFilter()
}
</script>

<template>
    <filter-card v-if="measureNames.length > 1">
        <h3 class="text-main mb-2">{{ $trans.get('common.type') }}</h3>

        <filter-checkbox-option
            v-for="name in measureNames"
            :key="name"
            :is-checked="selectedNames.includes(name)"
            :id="`name-${name}`"
            @changed="val => handleCheckedName(name, val)"
        >
            {{ name }}
        </filter-checkbox-option>
    </filter-card>
</template>
