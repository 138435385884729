<script setup lang="ts">
import Carousel from '@/components/Banner/Carousel.vue'
import SmallBanner from '@/components/Banner/SmallBanner.vue'
import useSmallBanners from '@/composables/home/useSmallBanners'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import EditBannerButton from '@/components/Banner/EditBannerButton.vue'
import LoadingBanners from '@/components/Banner/LoadingBanners.vue'

const { banners, loading } = useSmallBanners()
</script>

<template>
    <appear-transition>
        <div class="grid grid-cols-1 md:grid-cols-[2.33fr_1fr] lg:grid-cols-[2.3fr_1fr] xl:grid-cols-[2.3fr_1fr] gap-3 lg:gap-6">
            <carousel />

            <div v-if="banners.length > 1">
                <loading-banners v-if="loading" />

                <div
                    v-else
                    class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-3 lg:gap-6 relative"
                >
                    <edit-banner-button href="/admin/banners#product-banners" />

                    <small-banner
                        v-for="banner in banners"
                        :key="banner.id"
                        :banner="banner"
                    />
                </div>
            </div>
        </div>
    </appear-transition>
</template>
