<script setup lang="ts">
type Props = {
    href: string
}

const { href } = defineProps<Props>()

function redirect(): void {
    window.location.href = href
}
</script>

<template>
    <button
        v-if="$auth && $auth.is_admin"
        type="button"
        class="flex items-center justify-center absolute top-2 right-2 z-10 bg-white/50 w-12 h-12 rounded-full shadow-lg hover:scale-110 transition-transform"
        @click.prevent="redirect"
    >
        <i class="lni lni-pencil text-gray-700 text-xl font-black"></i>
    </button>
</template>