import type { FirebaseSignUpRequest, SignInMethod } from '@shared/types'
import firebase from '@/firebase'
import showToast from '@shared/modules/showToast'

export default (
    onSuccess: (params: FirebaseSignUpRequest) => void,
    onError: (err: any) => void,
    provider: firebase.auth.AuthProvider,
    serviceName: SignInMethod,
) => {
    firebase.auth()
        .signInWithPopup(provider)
        .then(resp => handleResponse(resp))
        .catch(err => onError(err))

    async function handleResponse(resp: firebase.auth.UserCredential): Promise<void> {
        const user = resp.user || null

        if (!user || !user.providerData) {
            const text = Lang.get('common.error_in_service') + ` ${serviceName}`
            showToast({ text, success: false })
            return
        }

        if (!user.providerData[0] || !user.providerData[0].email) {
            const text = Lang.get('common.error_cant_get_your_email')
            showToast({ text, success: false })
            return
        }

        const data = {
            email: user.providerData[0].email,
            name: user.displayName,
            photo: user.photoURL,
        }

        if (!data.name) {
            data.name = data.email!.split('@')[0]
        }

        const tokenField = document.querySelector('meta[name="csrf-token"]')
        const token = tokenField ? tokenField.getAttribute('content') : null

        if (!token) {
            const text = 'CSRF token error. You are probably doing something wrong.'
            showToast({ text, success: false })
            return
        }

        const params: FirebaseSignUpRequest = {
            token,
            sign_in_method: serviceName,
            email: data.email!,
            name: data.name!,
            photo_url: data.photo,
            register_from: 'website',
        }

        onSuccess(params)
    }
}
