<script setup lang="ts">
import GARemarketingEvent from '@/modules/ga/GARemarketingEvent'

type Props = {
    productsIds: number[]
}

const { productsIds } = defineProps<Props>()

GARemarketingEvent.push({
    ecomm_prodid: productsIds,
    ecomm_pagetype: 'searchresults',
    ecomm_totalvalue: '',
    ecomm_category: [],
})
</script>

<template></template>
