<script setup lang="ts">
import type { MinMaxRange } from '@shared/types'

type Props = {
    range: MinMaxRange
    currValue: number
}

type Emits = {
    (e: 'changed', newValue: number): void
}

const emit = defineEmits<Emits>()
const { range, currValue } = defineProps<Props>()
</script>

<template>
    <input
        type="number"
        :min="range.min"
        :max="range.max"
        :value="currValue"
        @change="emit('changed', $event.target.value)"
        class="border border-border rounded-md py-1.5 px-2 w-full text-[.9em]"
    />
</template>
