<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import SlideBottomTransition from '@shared/components/Transitions/SlideBottomTransition.vue'
import useLanguageSwitch from '@/composables/useLanguageSwitch'

const { switchLanguage, currentLang } = useLanguageSwitch()
</script>

<template>
    <popover v-slot="{ open }" class="relative">
        <popover-button class="h-full focus:outline-none text-sm lg:text-md">
            <div class="flex items-center justify-center w-full">
                <span v-if="currentLang">
                    <i class="mr-2 text-xs">{{ currentLang.flag }}</i>
                    {{ currentLang.title }}
                </span>
                <span v-else>
                    <i class="lni lni-flag" aria-hidden="true"></i>
                    {{ $locale }}
                </span>
                <i class="lni lni-chevron-down font-black ml-2" aria-hidden="true"></i>
            </div>
        </popover-button>

        <slide-bottom-transition>
            <popover-panel class="absolute left-0 z-10 mt-3 w-screen max-w-[250px] px-4">
                <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="relative flex flex-col gap-1 bg-page p-2">
                        <a
                            v-for="link in $languagesLinks"
                            :key="link.title"
                            href="javascript:"
                            @click="switchLanguage(link.slug)"
                            class="m-0 flex items-center p-2 transition duration-150 ease-in-out hover:bg-page-second focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 rounded-md"
                            :class="{ 'bg-main-extra-light': link.slug === $locale }"
                        >
                            <small>{{ link.flag }}</small>

                            <div class="ml-3">
                                <p class="text-gray-500">
                                    {{ link.title }}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </popover-panel>
        </slide-bottom-transition>
    </popover>
</template>
