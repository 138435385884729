<script setup lang="ts">
import type { Cart } from '@shared/types'
import { onMounted } from 'vue'
import GAProductEvent from '@/modules/ga/GAProductEvent'
import CheckoutTotal from '@/components/Cart/Checkout/CheckoutTotal.vue'
import WhiteBox from '@/components/WhiteBox.vue'
import CheckoutCashback from '@/components/Cart/Checkout/CheckoutCashback.vue'
import ChooseDelivery from '@/components/Cart/Checkout/ChooseDelivery.vue'
import CheckoutForm from '@/components/Cart/Checkout/CheckoutForm.vue'
import Privacy from '@/components/Cart/Checkout/Privacy.vue'
import useCheckout from '@/composables/cart/useCheckout'
import ChoosePaymentMethod from '@/components/Cart/Checkout/ChoosePaymentMethod.vue'

type Props = {
    cart: Cart
}

const { cart } = defineProps<Props>()
const { formData, sendOrder, isLoading } = useCheckout(cart)

onMounted(async () => {
    GAProductEvent.push({
        event: 'begin_checkout',
        ecommerce: {
            items: cart.cartItems.map(cartItem => ({
                item_name: cartItem.product.title,
                item_id: cartItem.product.id,
                item_variant: cartItem.product.measure_label,
                price: cartItem.product.price.toFixed(2),
                quantity: cartItem.quantity,
                ...GAProductEvent.getCategories(cartItem.product),
            })),
        },
    })
})
</script>

<template>
    <div class="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-4">
        <white-box>
            <checkout-form :form-data="formData" />

            <privacy />
        </white-box>

        <div class="space-y-4">
            <choose-delivery
                v-if="cart.delivery.items"
                :cart="cart"
            />

            <choose-payment-method
                v-if="cart.paymentMethod.items"
                :cart="cart"
            />

            <checkout-cashback
                 v-if="$auth && cart.cashback.user > 0"
                :cart="cart"
            />

            <checkout-total
                @create-order="sendOrder"
                :cart="cart"
                :is-loading="isLoading"
            />
        </div>
    </div>
</template>
