<script setup lang="ts">
import type { Product } from '@shared/types/models'
import GAProductEvent from '@/modules/ga/GAProductEvent'
import GARemarketingEvent from '@/modules/ga/GARemarketingEvent'

type Props = {
    product: Product
}

const { product } = defineProps<Props>()

const categories = GAProductEvent.getCategories(product)

GAProductEvent.push({
    event: 'view_item',
    ecommerce: {
        items: [
            {
                item_name: product.title,
                item_id: product.id,
                item_variant: product.measure_label,
                price: product.price.toFixed(2),
                ...categories,
            },
        ],
    },
})

GARemarketingEvent.push({
    ecomm_prodid: product.id,
    ecomm_pagetype: 'product',
    ecomm_totalvalue: product.price.toFixed(2),
    ecomm_category: Object.values(categories),
})
</script>

<template></template>
