<script setup lang="ts">
import type { Cart, CartItem } from '@shared/types'
import { events } from '@shared/appConfig'
import { onMounted } from 'vue'
import showToast from '@shared/modules/showToast'
import dispatchEvent from '@shared/modules/dispatchEvent'
import useGaEvents from '@/composables/cart/useGaEvents'
import CartCache from '@shared/modules/cart/CartCache'
import DataTable from '@/components/Cart/DataTable/DataTable.vue'
import HeadDetail from '@/components/Cart/DataTable/HeadDetail.vue'
import Row from '@/components/Cart/DataTable/Row.vue'
import BodyDetail from '@/components/Cart/DataTable/BodyDetail.vue'
import Message from '@shared/components/Message.vue'
import RemoveItemButton from '@/components/Cart/CartItems/RemoveItemButton.vue'
import CartTotal from '@/components/Cart/CartItems/CartTotal.vue'

type Props = {
    cart: Cart
}

const { cart } = defineProps<Props>()
const { gaProductEvent, gaRemarketingEvent } = useGaEvents()

onMounted(() => gaRemarketingEvent('cart', cart.cartItems, cart.totalSum))

async function removeCartItem(cartItem: CartItem) {
    gaProductEvent('remove_from_cart', cartItem)
    CartCache.removeCartItem(cartItem.product.slug)
    dispatchEvent(events.cartUpdated)
}

function isQuantityMoreThanAvailable(cartItem: CartItem): boolean {
    if (cartItem.quantity <= cartItem.product.quantity) {
        return false
    }

    cartItem.quantity = cartItem.product.quantity

    showToast({
        text: Lang.get('order.product_not_enough_quantity', {
            max: cartItem.product.quantity,
        }),
        success: false,
    })

    return true
}

async function changeQuantity(cartItem: CartItem): Promise<void> {
    if (isQuantityMoreThanAvailable(cartItem)) {
        return
    }

    gaProductEvent('add_to_cart', cartItem)
    CartCache.changeQuantity(cartItem.product.slug, cartItem.quantity)
    dispatchEvent(events.cartUpdated)
}
</script>

<template>
    <message v-if="cart.cartItems.length === 0" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        🤷 {{ $trans.get('common.your_cart_is_empty') }}
    </message>

    <data-table v-else>
        <template #head>
            <head-detail />
            <head-detail>{{ $trans.get('common.product_name') }}</head-detail>
            <head-detail>{{ $trans.get('common.price') }}</head-detail>
            <head-detail>{{ $trans.get('common.quantity') }}</head-detail>
            <head-detail>{{ $trans.get('common.subtotal') }}</head-detail>
            <head-detail></head-detail>
        </template>

        <row
            v-for="cartItem in cart.cartItems"
            :key="cartItem.product.slug"
        >
            <body-detail>
                <img
                    :src="cartItem.product.sm_image_uri ? `/${cartItem.product.sm_image_uri}` : '/storage/default.jpg'"
                    class="h-16 mx-auto rounded-md"
                />
            </body-detail>

            <body-detail>
                <a :href="`/product/${cartItem.product.slug}`">
                    {{ cartItem.product.title }}
                </a>
            </body-detail>

            <body-detail class="min-w-[100px] space-x-1" aria-label="Product price">
                <div class="flex flex-col">
                    <span class="md:hidden">
                        {{ $trans.get('common.price') }}:
                    </span>
                    <span>
                        <b class="mr-1">{{ cartItem.product.price }}</b>
                        <span>{{ $trans.get('common.uah') }}</span>
                    </span>
                </div>
            </body-detail>

            <body-detail>
                <input
                    v-model="cartItem.quantity"
                    type="number"
                    min="1"
                    class="w-20 px-2 py-1 border border-border rounded-md"
                    @change="changeQuantity(cartItem)"
                />
            </body-detail>

            <body-detail class="space-x-1">
                <span class="md:hidden">{{ $trans.get('common.subtotal') }}:</span>
                <b>{{ cartItem.product.price * cartItem.quantity }}</b>
                <span>{{ $trans.get('common.uah') }}</span>
            </body-detail>

            <body-detail class="text-center">
                <remove-item-button @click="removeCartItem(cartItem)" />
            </body-detail>
        </row>
    </data-table>

    <cart-total
        v-if="cart.cartItems.length > 0" class="mt-4"
        :cart="cart"
    />
</template>
