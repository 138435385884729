import { createApp } from 'vue/dist/vue.esm-bundler'
import smoothLoader from 'smooth-loader'
import assignGlobalValuesToApp from '@shared/modules/assignGlobalValuesToApp'
import ShowPasswordButton from '@shared/components/ShowPasswordButton.vue'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import Navbar from '@/components/Navbar/Navbar.vue'
import SearchNavbar from '@/components/Navbar/SearchNavbar.vue'
import AddToCartButton from '@/components/Cart/AddToCartButton.vue'
import ScrollDownButton from '@/components/Product/ScrollDownButton.vue'
import ProductControls from '@/components/Product/ProductControls.vue'
import ToTopButton from '@/components/Buttons/ToTopButton.vue'
import Banners from '@/components/Banner/Banners.vue'
import AddToWishlistSmallButton from '@/components/Product/AddToWishlistSmallButton.vue'
import SignInWith from '@/components/SignInWith.vue'
import Availability from '@/components/Product/Availability.vue'
import Filters from '@/components/Filters/Filters.vue'
import Sort from '@/components/Sort/Sort.vue'
import GAProductEvent from '@/components/GAEvents/GAProductEvent.vue'
import GARemarketingPageEvent from '@/components/GAEvents/GARemarketingPageEvent.vue'
import GARemarketingCategoryEvent from '@/components/GAEvents/GARemarketingCategoryEvent.vue'
import GARemarketingSearchEvent from '@/components/GAEvents/GARemarketingSearchEvent.vue'
import GAOrderEvent from '@/components/GAEvents/GAOrderEvent.vue'
import Tip from '@shared/components/Tip.vue'
import ThanksPage from '@/components/ThanksPage.vue'
import StatusPage from '@/components/Payment/StatusPage.vue'
import ProductTabs from '@/components/Product/ProductTabs/ProductTabs.vue'
import CookieConsent from '@/components/CookieConsent.vue'

const app = createApp({
    components: {
        ShowPasswordButton,
        LanguageSwitcher,
        Navbar,
        SearchNavbar,
        AddToCartButton,
        ScrollDownButton,
        ProductControls,
        ToTopButton,
        Banners,
        AddToWishlistSmallButton,
        SignInWith,
        Availability,
        Filters,
        Sort,
        GAProductEvent,
        GARemarketingPageEvent,
        GARemarketingCategoryEvent,
        GARemarketingSearchEvent,
        GAOrderEvent,
        Tip,
        ThanksPage,
        StatusPage,
        ProductTabs,
        CookieConsent,
    },
})

assignGlobalValuesToApp(app)

app.mount("#main")

smoothLoader('img[data-src]')
