import type { ServerResponse } from '@shared/types/server'
import type { PaymentMethodWithTitle } from '@shared/types'
import axios from 'axios'
import handleServerError from '@shared/modules/handleServerError'

export default async (): Promise<PaymentMethodWithTitle[]> => {
    const uri = '/api/v2/payment-methods'

    try {
        const response = await axios.get<ServerResponse<PaymentMethodWithTitle[]>>(uri, {
            params: { lang: Locale },
        })
        return response.data.data
    } catch (err) {
        handleServerError(err)
    }

    return []
}
