import type { PageType } from '@/types/ga'
import type { CartItem } from '@shared/types'
import GARemarketingEvent from '@/modules/ga/GARemarketingEvent'
import GAProductEvent from '@/modules/ga/GAProductEvent'

export default () => {
    function gaProductEvent(event: string, cartItem: CartItem) {
        GAProductEvent.push({
            event,
            ecommerce: {
                items: [
                    {
                        item_name: cartItem.product.title,
                        item_id: cartItem.product.id,
                        item_variant: cartItem.product.measure_label,
                        quantity: cartItem.quantity,
                        price: cartItem.product.price.toFixed(2),
                        ...GAProductEvent.getCategories(cartItem.product),
                    },
                ],
            },
        })
    }

    function gaRemarketingEvent(pageType: PageType, cartItems: CartItem[], total: number): void {
        GARemarketingEvent.push({
            ecomm_prodid: cartItems.map(item => item.product.id),
            ecomm_pagetype: pageType,
            ecomm_totalvalue: total.toFixed(2),
            ecomm_category: [],
        })
    }

    return {
        gaProductEvent,
        gaRemarketingEvent,
    }
}
