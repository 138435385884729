<script setup lang="ts">
import { computed } from 'vue'
import LargeButton from '@shared/components/Buttons/LargeButton.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import useCheckInWishlist from '@/composables/useCheckInWishlist'
import useAddToWishlist from '@/composables/useAddToWishlist'
import showToast from '@shared/modules/showToast'

type Props = {
    productId: number
}

const { productId } = defineProps<Props>()
const { productInWishlist } = useCheckInWishlist(productId)
const { addToWishlist, removeFromWishlist } = useAddToWishlist()

const buttonTitle = computed(() => {
    return productInWishlist.value
        ? Lang.get('common.delete_from_wishlist')
        : Lang.get('common.add_to_wishlist')
})

const buttonIcon = computed(() => {
    return productInWishlist.value ? 'lni lni-heart-filled' : 'lni lni-heart'
})

function handleClick(): void {
    if (!Auth) {
        showToast({
            text: Lang.get('common.need_to_be_auth'),
            success: false,
        })

        return
    }

    productInWishlist.value = !productInWishlist.value
    productInWishlist.value ? addToWishlist(productId) : removeFromWishlist(productId)
}
</script>

<template>
    <appear-transition>
        <large-button
            v-if="productInWishlist !== null"
            :icon="buttonIcon"
            type="outline"
            @click="handleClick"
            aria-label="Add to wishlist"
        >
            {{ buttonTitle }}
        </large-button>
    </appear-transition>
</template>
