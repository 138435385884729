<script setup lang="ts">
import type { ProductSortOption } from '@shared/types'
import { ref } from 'vue'
import { Listbox } from '@headlessui/vue'
import ListBoxButton from '@shared/components/ListBox/ListBoxButton.vue'
import ListBoxOption from '@shared/components/ListBox/ListBoxOption.vue'
import ListBoxOptions from '@shared/components/ListBox/ListBoxOptions.vue'
import replaceQueryArgument from '@shared/modules/replaceQueryArgument'

const sortOptions = ref<ProductSortOption[]>([
    { value: 'popularity', label: Lang.get('common.sort_by_popularity') },
    { value: 'price_asc', label: Lang.get('common.sort_by_price_asc') },
    { value: 'price_desc', label: Lang.get('common.sort_by_price_desc') },
    { value: 'reviews_amount', label: Lang.get('reviews.by_reviews_amount') },
])

const selected = ref<ProductSortOption>(sortOptions.value[0])

setCurrentSort()

function setCurrentSort(): void {
    const url = new URL(window.location.href)
    const sortValue = url.searchParams.get('sort')

    if (!sortValue) {
        return
    }

    const sortOption = sortOptions.value.find(option => option.value === sortValue)

    if (sortOption) {
        selected.value = sortOption
    }
}

function submitSort(): void {
    window.location.href = replaceQueryArgument('sort', selected.value.value)
}
</script>

<template>
    <Listbox v-model="selected" by="value">
        <div class="relative w-full max-w-[220px]">
            <list-box-button :label="selected.label" />

            <list-box-options>
                <list-box-option
                    v-for="option in sortOptions"
                    :key="option.value"
                    :option="option"
                    @click="submitSort"
                />
            </list-box-options>
        </div>
    </Listbox>
</template>
