<script setup lang="ts">
import type { Filters } from '@shared/types'
import type { ServerResponse } from '@shared/types/server'
import axios from 'axios'
import { onMounted, ref } from 'vue'
import isTouchScreen from '@shared/modules/isTouchScreen'
import PriceFilter from '@/components/Filters/PriceFilter.vue'
import MeasureNamesFilter from '@/components/Filters/MeasureNamesFilter.vue'
import FiltersLoading from '@/components/Filters/UI/FiltersLoading.vue'
import SlideBottomTransition from '@shared/components/Transitions/SlideBottomTransition.vue'
import CategoriesFilter from '@/components/Filters/CategoriesFilter.vue'
import StateFilter from '@/components/Filters/StateFilter.vue'
import WeightFilter from '@/components/Filters/WeightFilter.vue'
import FilterButton from '@/components/Filters/UI/FilterButton.vue'

const loading = ref<boolean>(false)
const filters = ref<Filters | null>(null)
const showFilters = ref<boolean>(!isTouchScreen())
const filterNames = [
    'categories',
    'price',
    'weight',
    'measure_names',
    'availability',
]

onMounted(() => fetchFilters())

function fetchFilters(): void {
    loading.value = true

    axios
        .get<ServerResponse<Filters>>(getRequestUri())
        .then(resp => (filters.value = resp.data.data))
        .catch(e => console.error(e))
        .finally(() => (loading.value = false))
}

function getRequestUri(): string {
    const urlParts = location.pathname.split('/')
    const lastUrlPart = urlParts[urlParts.length - 1]
    let requestUri = `/api/v2/filters?lang=${Locale}`

    if (!lastUrlPart) {
        return requestUri
    }

    return `${requestUri}&category=${lastUrlPart.replaceAll('/', '')}`
}

function clearFilters(): void {
    const currUrl = new URL(window.location.href)

    filterNames.forEach(name => currUrl.searchParams.delete(name))

    // if there is a 'page' query parameter, we should remove it
    if (window.location.search.includes('page')) {
        currUrl.searchParams.delete('page')
    }

    window.location.href = currUrl.toString()
}
</script>

<template>
    <div class="flex gap-2">
        <filter-button v-if="isTouchScreen()" @click="showFilters = !showFilters">
            {{ $trans.get('common.filters') }}
        </filter-button>

        <filter-button @click="clearFilters">
            {{ $trans.get('common.clear_filters') }}
        </filter-button>
    </div>

    <slide-bottom-transition>
        <div v-if="showFilters" class="mt-4">
            <filters-loading v-if="loading" />

            <div v-else-if="filters" class="space-y-3">
                <categories-filter :categories="filters.categories" />
                <price-filter :price-range="filters.price" />
                <state-filter :states="filters.states" />
                <weight-filter :weight-range="filters.weight" />
                <measure-names-filter :measure-names="filters.measure_names" />
            </div>
        </div>
    </slide-bottom-transition>
</template>
