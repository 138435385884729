<script setup lang="ts">
import { storageKeys } from '@shared/appConfig'
import { ref } from 'vue'

const savedAgreement = localStorage.getItem(storageKeys.cookieConsent)
const isAgreed = ref<boolean>(savedAgreement !== null)

function agree() {
    isAgreed.value = true
    localStorage.setItem(storageKeys.cookieConsent, '1')
}
</script>

<template>
    <div
        v-if="!isAgreed"
        class="flex flex-col md:flex-row items-center gap-4 fixed bottom-2 w-auto left-2 md:left-auto   right-2 lg:right-3 z-30 bg-gray-600 text-white shadow-lg rounded-lg py-3 px-5"
    >
        <p>
            {{ $trans.get('common.cookie_consent_text') }}
            <a
                href="/docs/user-agreement"
                class="text-blue-200 hover:text-blue-300 underline"
                target="_blank"
            >
                {{ $trans.get('common.in_user_agreement') }}
            </a>.
        </p>

        <button
            @click="agree"
            type="button"
            class="bg-main hover:bg-main-hover tran text-white rounded-lg px-7 py-2 text-center min-w-max"
        >
            <i class="lni lni-checkmark mr-2" aria-hidden="true"></i>
            {{ $trans.get('common.understand') }}
        </button>
    </div>
</template>